import { Box } from '@creditas-ui/react'
import { Divider } from 'antd'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getLeadDetails } from '../../../../api/api'
import { formatMoney } from '../../../../shared/components/helpers'
import { sumTotal } from '../../../../utils/sumTotal'
import { FeedItemDescription, Item, Label, Name, Title, Value } from '../../Lead.styles'
import { ActiveContractWarning } from '../ActiveContractWarning'

function anyPersonHasActiveContract(leadDetail) {
  return leadDetail.persons?.some((p) => p.hasActiveContract)
}

export default function Header({ leadId }) {
  const [leadDetail, setLeadDetail] = useState([])

  const loadLeadDatailsData = async () => {
    try {
      const response = await getLeadDetails(leadId)

      setLeadDetail(response.data)
    } catch (error) {
      toast.error('Ocorreu um erro inesperado! Atualize a página e tente novamente.')
    }
  }

  useEffect(() => {
    loadLeadDatailsData()
  }, [])

  return (
    <>
      <Name variant='h3'>{leadDetail.lead?.fullName}</Name>
      <br />
      <br />
      <br />
      <Title variant='h4'>Resumo da proposta</Title>
      <FeedItemDescription>
        Você pode aprovar a análise com base nos comprovantes recebidos e informando o valor do
        crédito aprovado que o lead irá ter. Caso o lead e o solidário não possuírem renda
        compátivel, você pode reprovar a análise.
      </FeedItemDescription>
      <Divider />
      {anyPersonHasActiveContract(leadDetail) && (
        <>
          <ActiveContractWarning />
          <Divider />
        </>
      )}
      <Box>
        <Item>
          <Label>Imobiliária</Label>
          <Value>{leadDetail.realEstate?.name}</Value>
        </Item>
        <Item>
          <Label>CNPJ</Label>
          <Value>
            {leadDetail.realEstate?.cnpj.replace(
              /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
              '$1 $2 $3/$4-$5',
            )}
          </Value>
        </Item>
      </Box>
      <Divider />
      <Box>
        <Item>
          <Label>Valor do aluguel:</Label>
          <Value>{formatMoney(leadDetail.rentDetails?.rentAmount)}</Value>
        </Item>
        <Item>
          <Label>Condomínio:</Label>
          <Value>{formatMoney(leadDetail.rentDetails?.condominiumAmount)}</Value>
        </Item>
        <Item>
          <Label>IPTU:</Label>
          <Value>{formatMoney(leadDetail.rentDetails?.iptuAmount)}</Value>
        </Item>
        <Item>
          <Label>Total:</Label>
          <Value>
            {formatMoney(
              sumTotal(
                leadDetail.rentDetails?.rentAmount,
                leadDetail.rentDetails?.condominiumAmount,
                leadDetail.rentDetails?.iptuAmount,
              ),
            )}
          </Value>
        </Item>
      </Box>
    </>
  )
}
