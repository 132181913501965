const CREDITAS_AFFILIATES_TOKEN = 'home-solutions-frontend'
const CREDITAS_AFFILIATES_STATE = 'creditas-affiliates-state'

const getAffiliatesAuthSession = () => localStorage.getItem(CREDITAS_AFFILIATES_TOKEN)

const getCredentials = () => {
  const token = getAffiliatesAuthSession()

  if (token === null) {
    throw new Error("There's no session storage in local storage")
  }

  return token
}

const destroyCredentials = () => {
  localStorage.clear()
}

const storeCredentials = (token) => {
  localStorage.setItem(CREDITAS_AFFILIATES_TOKEN, token)
}

const storeAffiliatesState = (state) => {
  localStorage.setItem(CREDITAS_AFFILIATES_STATE, JSON.stringify(state))
}

export {
  getAffiliatesAuthSession,
  getCredentials,
  destroyCredentials,
  storeCredentials,
  storeAffiliatesState,
}
