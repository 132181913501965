import { ModalRoot, ModalHeader, ModalBody, ModalFooter } from '@creditas-ui/modal'

const AnalysisModal = ({
  allowClose,
  children,
  isOpen,
  openModal,
  closeModal,
  title,
  onPrimaryButtonClick,
  primaryButtonLabel,
  secondaryButtonLabel = 'Cancelar',
}) => {
  return (
    <>
      <ModalRoot
        isOpen={isOpen}
        closeModal={allowClose ? closeModal : () => {}}
        openModal={openModal}
        allowClose={allowClose}
        size='medium'
        zIndex={9999}
      >
        <ModalHeader title={title} />
        <ModalBody resetStyle>{children}</ModalBody>
        <ModalFooter
          variant='horizontalAlignedButtons'
          primaryLabel={primaryButtonLabel}
          onPrimaryButtonClick={onPrimaryButtonClick}
          secondaryLabel={secondaryButtonLabel}
          onSecondaryButtonClick={closeModal}
        />
      </ModalRoot>
    </>
  )
}

export default AnalysisModal
