import { styled } from '@creditas/stylitas'
import { Typography } from '@creditas/typography'

const ContractStatusValue = styled(Typography)`
  color: #1b75dd;
  font-size: 16px;
  font-weight: 400;
`

const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 20% 32px;
`

const Label = styled(Typography)`
  color: ${({ theme }) => theme.bColorNeutral60};
  font-size: 16px;
  font-weight: 400;
`

const Value = styled(Typography)`
  color: ${({ theme }) => theme.bColorNeutral90};
  font-size: 16px;
  font-weight: 600;
`

const Name = styled(Typography)`
  color: ${({ theme }) => theme.bColorNeutral90};
  font-weight: 300;

  span {
    font-weight: 700;
  }
`

export { ContractStatusValue, Item, Label, Value, Name }
