import React from 'react'
import { styled } from '@creditas/stylitas'
import { compose, withDefaultProps, withForwardRef } from '@creditas/utils'

const TableCellStyle = styled.td`
  color: ${({ theme }) => theme.bColorNeutral80};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 24px;
`

const TableCellContent = styled.div`
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const TableCellContentWithLineBreak = styled.div`
  max-width: 300px;
`

const propTypes = {}

const defaultProps = {
  component: 'td',
}

const enhancer = compose(withForwardRef, withDefaultProps(propTypes, defaultProps))

const element = enhancer(function TableCell(props) {
  const { ref, component, className, children, lineBreak, ...other } = props

  return (
    <TableCellStyle ref={ref} as={component} className={className} {...other}>
      {lineBreak ? (
        <TableCellContentWithLineBreak>{children}</TableCellContentWithLineBreak>
      ) : (
        <TableCellContent>{children}</TableCellContent>
      )}
    </TableCellStyle>
  )
})

export { element as TableCell }
