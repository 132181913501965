import { ModalRoot, ModalHeader, ModalBody, ModalFooter } from '@creditas-ui/modal'

const ModalDescription = ({
  children,
  isOpen,
  openModal,
  closeModal,
  title,
  onPrimaryButtonClick,
  size,
}) => {
  return (
    <>
      <ModalRoot
        isOpen={isOpen}
        closeModal={closeModal}
        openModal={openModal}
        allowClose
        size={size ?? 'medium'}
      >
        <ModalHeader title={title} />

        <ModalBody>{children}</ModalBody>
        <ModalFooter
          primaryLabel='Confirmar'
          onPrimaryButtonClick={onPrimaryButtonClick}
          secondaryLabel='Cancelar'
          onSecondaryButtonClick={closeModal}
        />
      </ModalRoot>
    </>
  )
}

export default ModalDescription
