import * as React from 'react'

function BookOpenedIcon(props) {
  return (
    <svg
      width={26}
      height={25}
      viewBox='0 0 26 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M15.965 5.73c-.989.314-1.964.721-2.977 1.246-2.807-1.454-5.316-2.032-8.64-2.13a1.157 1.157 0 00-.42.07 1.09 1.09 0 00-.359.215.996.996 0 00-.239.327.93.93 0 00-.083.387v11.837c0 .541.469.99 1.054.998 3.347.047 5.867.617 8.687 2.15 2.82-1.533 5.34-2.103 8.687-2.149.282-.006.551-.113.748-.3a.958.958 0 00.306-.699V5.845a.929.929 0 00-.082-.387.995.995 0 00-.24-.328 1.09 1.09 0 00-.358-.215 1.156 1.156 0 00-.42-.07c-.656.02-1.274.064-1.876.124'
        stroke='#1F2D27'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.466 3.845c-.596.098-1.174.223-1.742.376-.222.063-.415.19-.552.363a.943.943 0 00-.207.595v4.196l1.894-1.167 1.894 1.167V4.832c0-.626-.62-1.097-1.287-.987zM12.988 6.98v13.85M16.235 14.602a16.884 16.884 0 013.247-.64M6.494 13.962c1.14.117 2.207.321 3.247.64M9.741 10.602a16.812 16.812 0 00-3.247-.64'
        stroke='#1F2D27'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export { BookOpenedIcon }
