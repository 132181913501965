import {
  Box,
  Radio,
  ButtonPrimaryTextOnly,
  InputCurrency,
  GlobalIcons,
  RadioGroup,
  TextArea,
  StatusLabel,
} from '@creditas-ui/react'
import { FeedbackMessage } from '@creditas/feedback-message'
import { useEffect, useState } from 'react'
import { formatCpf, formatMoney } from '../../../../shared/components/helpers'
import {
  ButtonDocs,
  ButtonRejectLead,
  Item,
  Label,
  ButtonRequestNewIncomeDocs,
  Title,
  Value,
  RadioRow,
  ColWithBorder,
  RadioGroupStyled,
  CommentRow,
  InputTextAreaStyled,
  PersonBorder,
  PersonMargin,
  OpenModalDocLabel,
  DivShowDocIconText,
  LabelShowDoc,
  ButtonTextConfirmedIncome,
  ButtonConfirmedIncome,
  ButtonTextRequestNewIncomeDocs,
  ButtonTextRejectedLead,
  LabelTextRejectReason,
} from './PersonItens.styles'
import { peding_resend_income_documents, reanalyse, reject } from '../../status'
import ModalAprove from '../ModalDocs'
import { useNavigate } from 'react-router-dom'
import ModalDescription from '../Modal'
import ModalReject from '../Modal'
import { toast } from 'react-toastify'

import { Col, Divider } from 'antd'
import { getLeadDetails, postManualAnalysis } from '../../../../api/api'
import { Loading } from '../../../../shared/components/Icons'
import { StyledCenterIcon } from '../../../../shared/components/Document/Document.style'
import { FiEye } from 'react-icons/fi'

export default function PersonItens({ leadId, visibility, modalDataFun }) {
  const userToken = localStorage.getItem('username') || null

  const [confirmedIncome, setConfirmedIncome] = useState(0)
  const [manualAnalysisResult, setManualAnalysisResult] = useState('')
  const [income, setIncome] = useState([])
  const [changeResidency, setChangeResidency] = useState({})
  const [docs, setDocs] = useState([])
  const [consultantName] = useState(JSON.parse(userToken))
  const [description, setDescription] = useState('')
  const [rejectedDetailed, setRejectedDetailed] = useState('')
  const [comments, setComments] = useState([])
  const [leadDetail, setLeadDetail] = useState([])
  const [olxApplicationId, setOlxApplicationId] = useState('')
  const [loading, setLoading] = useState(false)

  const [modalVisible, setModalVisible] = useState(false)
  const [modalAprove, setModalAprove] = useState(false)
  const [modalPending, setModalPending] = useState(false)

  const navigate = useNavigate()

  const loadLeadDatailsData = async () => {
    try {
      setLoading(true)
      const response = await getLeadDetails(leadId)

      setLeadDetail(response.data)
      setOlxApplicationId(leadId)
    } catch (error) {
      navigate('/dashboard')
      toast.error('Ocorreu um erro inesperado! Atualize a página e tente novamente.')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setConfirmedIncome('')
    setDocs([])
    setDescription('')
    setManualAnalysisResult('')
    loadLeadDatailsData()
  }, [])

  const getPersons = () => {
    return leadDetail.persons.map((person) => {
      const incomeArrayUniquePersonId = [
        ...new Map(income.map((item) => [item['personId'], item])).values(),
      ]

      const confirmed = incomeArrayUniquePersonId.find((i) => i?.personId === person?.personId)

      const personResponse = {
        cpf: person.cpf,
        personId: person.personId,
        confirmedIncome: confirmed?.confirmedIncome,
        changeResidency: changeResidency[person.personId],
        docs: person.incomeProofs
          .find((item) => !item.readOnly)
          .docs.map((doc) => {
            const analysisDoc = docs.find((docResult) => docResult.docId === doc.docId)
            return {
              docId: doc.docId,
              documentResult: analysisDoc?.documentResult,
              comment: analysisDoc?.comment,
            }
          }),
      }
      return personResponse
    })
  }

  async function analysisManual(toastSuccessMessage) {
    try {
      let data = {
        olxApplicationId,
        consultantName,
        persons: getPersons(),
        manualAnalysisResult,
        description,
        rejectedDetailed,
      }

      const response = await postManualAnalysis(JSON.stringify(data))

      if (response) {
        toast.success(toastSuccessMessage)
        navigate('/dashboard')
        setConfirmedIncome('')
        setDocs([])
        setDescription('')
        setManualAnalysisResult('')
      }
    } catch (error) {
      console.log(error)
      toast.error(`Erro no envio da informação, tente novamente.`)
    }
  }

  const handleAproveIncome = () => {
    setManualAnalysisResult(reanalyse)
    setModalAprove(true)
  }

  function rejectIncomeLead() {
    setModalVisible(true)
    setManualAnalysisResult(reject)
  }

  function handleChangeIncome(rawNumber) {
    setConfirmedIncome(rawNumber)
    setManualAnalysisResult(reanalyse)
  }

  function handleIncome(item) {
    const confirmed = {
      personId: item.personId,
      confirmedIncome: confirmedIncome,
    }
    setIncome([...income, confirmed])
  }

  function handleRadioGroupRejectOnChange(value) {
    setRejectedDetailed(value)
  }

  const handleCommentOnChange = (value, index, docItem) => {
    if (value.length >= 255) {
      return toast.info('O campo de comentário deve ter no máximo 255 caracteres!')
    }

    let indexCommentDelete = comments.findIndex((commentItem) => commentItem.id === index)

    if (indexCommentDelete >= 0) {
      comments.splice(indexCommentDelete, 1)
    }

    const newComment = {
      id: index,
      value,
    }
    setComments([...comments, newComment])

    let indexDocDelete = docs.findIndex((docResult) => docResult.docId === docItem.docId)

    if (indexDocDelete >= 0) {
      const document = {
        personId: docs[indexDocDelete].personId,
        docId: docs[indexDocDelete].docId,
        documentResult: docs[indexDocDelete].documentResult,
        comment: value,
      }

      docs.splice(indexDocDelete, 1)
      setDocs([...docs, document])
    }
  }

  const handleOnChangeResidency = (value, person) => {
    setChangeResidency({
      ...changeResidency,
      [person.personId]: value,
    })
  }

  const handleOnChange = (value, docItem, item, itemIndex) => {
    let indexDelete = docs.findIndex((docResult) => docResult.docId === docItem.docId)

    if (indexDelete >= 0) {
      docs.splice(indexDelete, 1)
    }

    const document = {
      personId: item.personId,
      docId: docItem.docId,
      documentResult: (docs[docItem] = value),
      comment: comments.find((commentItem) => commentItem.id === itemIndex)?.value,
    }
    setDocs([...docs, document])
  }

  const handlePendingDocuments = () => {
    setManualAnalysisResult(peding_resend_income_documents)
    setModalPending(true)
  }

  function Reanalysis() {
    analysisManual('Reanálise enviada com sucesso!')
    if (description !== '' || manualAnalysisResult !== '') {
      setModalPending(false)
      setDocs([])
    }
    setDescription('')
  }

  function RejectedIncome() {
    if (description !== '' || manualAnalysisResult !== '') {
      setConfirmedIncome('')
      analysisManual('Renda rejeitada!')
      setModalVisible(false)
    }
  }

  function isResidencyChangeFilled() {
    const persons = leadDetail.persons.filter((p) => p.hasActiveContract)
    if (persons.length == 0) return true
    return persons.every((p) => changeResidency[p.personId])
  }

  function AproveIncome() {
    if ((confirmedIncome !== '' || manualAnalysisResult !== '') && isResidencyChangeFilled()) {
      analysisManual('Renda aprovada com sucesso')
      setModalAprove(false)
    }
  }

  function openModal(opened, modalData) {
    visibility(opened)
    modalDataFun(modalData)
  }

  const getIncomeProofDocs = (personItem, index) => {
    const analysisItem = personItem.incomeProofs.find((item) => !item.readOnly)

    if (loading) {
      return (
        <StyledCenterIcon>
          <Loading />
        </StyledCenterIcon>
      )
    }

    return analysisItem?.docs.map((docItem, itemIndex) => (
      <div key={index + docItem?.docId}>
        {docItem.docId ? (
          <ColWithBorder>
            <Col>
              <Item>
                <ButtonDocs
                  onClick={() =>
                    openModal(true, {
                      docId: docItem?.docId,
                      title:
                        personItem?.fullName +
                        ' - ' +
                        docItem?.typeDescription +
                        ' - ' +
                        docItem?.docId,
                    })
                  }
                >
                  <OpenModalDocLabel>
                    <div id={docItem?.docId + '-docLabel'}>
                      <DivShowDocIconText>
                        <GlobalIcons
                          Icon={FiEye}
                          size='medium'
                          color={'rgba(61, 156, 150, 1)'}
                          style={{ marginLeft: '1%' }}
                        />
                        <LabelShowDoc>Visualizar</LabelShowDoc>
                      </DivShowDocIconText>
                      <div>
                        {docItem?.typeDescription} - {docItem.docId}
                      </div>
                    </div>
                  </OpenModalDocLabel>
                </ButtonDocs>
              </Item>
            </Col>
            <RadioRow>
              <RadioGroupStyled
                onChange={(value) => {
                  handleOnChange(value, docItem, personItem, itemIndex)
                }}
                id={docItem.docId}
              >
                <Radio
                  value='APPROVED'
                  label='Aprovado'
                  id={docItem.docId + '_radio_approved'}
                  spaceX='1rem'
                />
                <Radio
                  value='INCOMPLETE_CUT'
                  label='Incompleto'
                  id={docItem.docId + '_radio_incomplete_cut'}
                  spaceX='1rem'
                />
                <Radio
                  value='UNREADABLE'
                  label='Ilegível'
                  id={docItem.docId + '_radio_unreadable'}
                  spaceX='1rem'
                />
                <Radio
                  value='INCORRECT_MONTH'
                  label='Mês incorreto'
                  id={docItem.docId + '_radio_incorrect_month'}
                  spaceX='1rem'
                />
              </RadioGroupStyled>
            </RadioRow>
            <CommentRow>
              <Label>Comentário:</Label>
              <InputTextAreaStyled
                width='400px'
                characterLimits={[255, 255]}
                name={'commentInputTextArea'}
                onChange={(e) => handleCommentOnChange(e.target.value, itemIndex, docItem)}
              />
            </CommentRow>
          </ColWithBorder>
        ) : (
          <FeedbackMessage variant='warning'>Aguardando novo comprovante</FeedbackMessage>
        )}
      </div>
    ))
  }

  const navigateAnalysisHistory = (analysisHistoryData) => {
    localStorage.setItem('pageData', JSON.stringify(analysisHistoryData))
    window.open('/lead/' + olxApplicationId + '/analysis-history', '_blank')
  }

  return (
    <>
      <ModalAprove
        isOpen={modalAprove}
        title='Renda Aprovada'
        closeModal={() => setModalAprove(false)}
        openModal={modalAprove}
        onPrimaryButtonClick={() => AproveIncome()}
        allowClose
        size='medium'
      ></ModalAprove>

      <ModalDescription
        isOpen={modalPending}
        title='Motivos para reanálise'
        closeModal={() => setModalPending(false)}
        openModal={modalPending}
        onPrimaryButtonClick={() => Reanalysis()}
        allowClose
        size='medium'
      >
        <TextArea
          name='InputTextArea'
          value={description}
          style={{ width: 500 }}
          onChange={(e) => setDescription(e.target.value)}
        />
      </ModalDescription>

      <ModalReject
        isOpen={modalVisible}
        title='Rejeitar Proposta'
        closeModal={() => setModalVisible(false)}
        openModal={modalVisible}
        onPrimaryButtonClick={() => RejectedIncome()}
        allowClose
        size='medium'
      >
        <LabelTextRejectReason>
          Selecione abaixo o motivo de recusa da proposta
        </LabelTextRejectReason>
        <RadioGroup
          spaceY='1rem'
          onChange={(value) => {
            handleRadioGroupRejectOnChange(value)
          }}
        >
          <Radio value='INCOME_FRAUD' label='Documentos com indícios de fraude' id='INCOME_FRAUD' />
          <Radio value='INCOME_UPTIME' label='Tempo de atividade' id='INCOME_UPTIME' />
        </RadioGroup>
        <div style={{ margin: '3%' }} />
        <TextArea
          name='InputTextArea'
          placeholder='Adicionar comentários'
          style={{ width: 500 }}
          onChange={(e) => setDescription(e.target.value)}
        />
      </ModalReject>

      {leadDetail?.persons?.map((item, index) => (
        <PersonBorder key={'PersonBorder' + index}>
          <PersonMargin key={'PersonMargin' + index}>
            <Title variant='h5'>
              {item?.hasActiveContract && <StatusLabel variant='warning' />}Proponente -{' '}
              {item?.fullName}
            </Title>
            <Item>
              <Label>Nome:</Label>
              <Value>{item?.fullName}</Value>
            </Item>
            <Item>
              <Label>CPF</Label>
              <Value>{formatCpf(item?.cpf)}</Value>
            </Item>
            <Item>
              <Label>Telefone:</Label>
              <Value>{item?.phone}</Value>
            </Item>
            <Item>
              <Label>E-mail:</Label>
              <Value>{item?.email}</Value>
            </Item>
            <Item>
              <Label>Profissional:</Label>
              <Value>{item?.employmentRelationship}</Value>
            </Item>
            <Item>
              <Label>Renda Mensal:</Label>
              <Value>{formatMoney(item?.monthlyIncome)}</Value>
            </Item>
            <Divider />
            {item?.hasActiveContract && (
              <>
                <Label>Selecione o motivo pelo segundo endosso</Label>
                <ColWithBorder>
                  <OpenModalDocLabel>
                    <RadioRow>
                      <RadioGroupStyled
                        onChange={(value) => handleOnChangeResidency(value, item)}
                        id={index}
                      >
                        <Radio
                          value='RESIDENCY_CHANGE'
                          label='Troca de Imóvel'
                          id={index + '_radio_residency_change'}
                          spaceX='1rem'
                        />
                        <Radio
                          value='RESIDENCY_NEW'
                          label='Novo Imóvel'
                          id={index + '_radio_residency_new'}
                          spaceX='1rem'
                        />
                      </RadioGroupStyled>
                    </RadioRow>
                  </OpenModalDocLabel>
                </ColWithBorder>
                <Divider />
              </>
            )}

            <Label>Selecione o status do documento</Label>

            {getIncomeProofDocs(item, index)}

            <ButtonPrimaryTextOnly
              data-testid='btn-new-proposal'
              variant='primary'
              onClick={() => navigateAnalysisHistory(item)}
              target={'_blank'}
            >
              Histórico de Análises
            </ButtonPrimaryTextOnly>

            <br />
            <InputCurrency
              label={'Renda comprovada - ' + item?.fullName}
              onBlur={() => handleIncome(item)}
              onChange={(_, rawNumber) => handleChangeIncome(rawNumber)}
              suffix='R$'
            />
            <br />
          </PersonMargin>
        </PersonBorder>
      ))}

      <Box style={{ paddingTop: '10px' }}>
        <Item>
          <div style={{ display: 'flex', width: '65%' }}>
            <ButtonConfirmedIncome
              data-testid='btn-new-proposal'
              variant='primary'
              onClick={() => handleAproveIncome()}
            >
              <ButtonTextConfirmedIncome>Confirmar renda</ButtonTextConfirmedIncome>
            </ButtonConfirmedIncome>
            <ButtonRequestNewIncomeDocs onClick={() => handlePendingDocuments()}>
              <ButtonTextRequestNewIncomeDocs>Solicitar Documentos</ButtonTextRequestNewIncomeDocs>
            </ButtonRequestNewIncomeDocs>
          </div>
          <div style={{ width: '35%' }}>
            <ButtonRejectLead variant='primary' onClick={() => rejectIncomeLead()}>
              <ButtonTextRejectedLead>Rejeitar Proposta</ButtonTextRejectedLead>
            </ButtonRejectLead>
          </div>
        </Item>
      </Box>
    </>
  )
}
