import { ErrorContainer, ErrorTitle, ErrorDescription } from './components/Error.styles'

const Error = () => {
  return (
    <ErrorContainer>
      <ErrorTitle>Ops! Ocorreu um erro interno</ErrorTitle>
      <ErrorDescription variant='paragraph'>
        Recarregue a página ou tente novamente mais tarde, caso o erro persista.
      </ErrorDescription>
    </ErrorContainer>
  )
}

export { Error }
