import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from '../presentation/Home/Home'
import Lead from '../presentation/Lead/Lead'
import LeadDetails from '../presentation/LeadDetails/LeadDetails'
import { Login } from '../presentation/Login/login'
import { Error } from '../shared/components/Error/Error'
import { Sidebar } from '../shared/components/Sidebar/Sidebar'
import LeadsList from '../presentation/PersonLeadsList/LeadsList'
import LeadAnalysisHistory from '../presentation/LeadAnalysisHistory/LeadAnalysisHistory'
import { storeCredentials } from '../infrastructure/authentication'
import jwt_decode from 'jwt-decode'
import LeadContractsList from '../presentation/LeadContracts/LeadContractsList/LeadContractsList'
import LeadContractDetails from '../presentation/LeadContracts/LeadContractDetails/LeadContractDetails'

function SidebarComponent({ children }) {
  return (
    <>
      <Sidebar />
      {children}
    </>
  )
}

function UserValidation({ children }) {
  const token = localStorage.getItem('home-solutions-frontend') || null

  if (token) {
    const userToken = jwt_decode(token)

    storeCredentials(token)
    localStorage.setItem('username', JSON.stringify(userToken.username))
  }

  return <>{children}</>
}

const Router = () => {
  return (
    <BrowserRouter>
      <UserValidation>
        <Routes>
          <Route path='/' exact element={<Login />} />
          <Route
            path='/dashboard'
            exact
            element={
              <SidebarComponent>
                <Home />
              </SidebarComponent>
            }
          />
          <Route
            path='/leads'
            exact
            element={
              <SidebarComponent>
                <LeadsList />
              </SidebarComponent>
            }
          />
          <Route
            path='/lead/:id/details'
            exact
            element={
              <SidebarComponent>
                <LeadDetails />
              </SidebarComponent>
            }
          />
          <Route
            path='/lead/:id'
            exact
            element={
              <SidebarComponent>
                <Lead />
              </SidebarComponent>
            }
          />
          <Route
            path='/lead/:id/analysis-history'
            exact
            element={
              <SidebarComponent>
                <LeadAnalysisHistory />
              </SidebarComponent>
            }
          />
          <Route
            path='/lead'
            exact
            element={
              <SidebarComponent>
                <Lead />
              </SidebarComponent>
            }
          />
          <Route
            path='/contracts'
            exact
            element={
              <SidebarComponent>
                <LeadContractsList />
              </SidebarComponent>
            }
          />
          <Route
            path='/contract/:id'
            exact
            element={
              <SidebarComponent>
                <LeadContractDetails />
              </SidebarComponent>
            }
          />
          <Route path='*' exact element={<Error />} />
        </Routes>
      </UserValidation>
    </BrowserRouter>
  )
}

export default Router
