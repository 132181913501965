import { Quote } from '@creditas-ui/quote'
import { Bold } from './ActiveContractWarning.styles'
import React from 'react'

const ActiveContractWarning = () => {
  return (
    <Quote variant='warning'>
      <React.Fragment key='.0'>
        <Bold>Atenção!</Bold> Identificamos que um dos CPFs presentes na proposta já possui um
        segundo endosso ativo. Revise os detalhes do lead e comunique a imobiliária.
      </React.Fragment>
    </Quote>
  )
}

export { ActiveContractWarning }
