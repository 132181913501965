import React from 'react'
import { Hero, Title } from './Home.styles'

const Header = ({ title }) => {
  return (
    <Hero>
      <Title data-testid='leads-list-title' variant='h4'>
        {title}
      </Title>
    </Hero>
  )
}

export { Header }
