import { useState } from 'react'
import { LayoutWrapper } from '../../shared/components/Home.styles'
import { MainLayout } from '../../shared/components/MainLayout/MainLayout'

import { Name } from '../Lead/Lead.styles'
import { Header } from '../../shared/components'
import { SearchBar } from './components/SearchBar'
import { LeadsDetailsList } from './components/LeadsDetailsList'
import { LoadingDiv, SearchBarView, TextDiv } from './LeadsList.styles'
import { Loading } from '../../shared/components/Icons'
import { getLeadsListByDocument, getLeadsListByOlxId } from '../../api/api'
import { toast } from 'react-toastify'
import { RequireAuth } from '../../utils/requiredAuthValidation'

const LeadsList = () => {
  const [leadsListData, setLeadsListData] = useState([])
  const [loading, setLoading] = useState(false)
  const [hasSearch, setHasSearch] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const loadLeads = async () => {
    try {
      setLoading(true)
      setHasSearch(true)

      const trimSearchValue = searchValue.replace(/^[, . -]+|[, . -]+$|[, . -]+/g, '').trim()
      let response = {}

      if (trimSearchValue.length > 11) {
        response = await getLeadsListByOlxId(searchValue)
      } else {
        response = await getLeadsListByDocument(trimSearchValue)
      }

      setLeadsListData(response.data)
    } catch (error) {
      toast.error('Ocorreu um erro inesperado! Atualize a página e tente novamente.')
    } finally {
      setLoading(false)
    }
  }

  const onInputChange = ({ target }) => {
    if (!target.value) {
      setLeadsListData([])
      return
    }
    setSearchValue(target.value)
  }

  const onSubmitHandler = (e) => {
    e.preventDefault()
    loadLeads()
  }

  return (
    <RequireAuth navigationRoute={'/'}>
      <LayoutWrapper>
        <MainLayout>
          <Header title='GreyZone - Análise de Credito Detalhes' />
          <SearchBarView>
            <SearchBar
              onSubmit={onSubmitHandler}
              onChange={onInputChange}
              onSearchButtonSubmit={() => loadLeads()}
            />
          </SearchBarView>
          {loading ? (
            <>
              <LoadingDiv>
                <Loading />
              </LoadingDiv>
              <TextDiv>
                <Name variant='h6'>A consulta pode demorar alguns segundos.</Name>
              </TextDiv>
            </>
          ) : (
            <LeadsDetailsList leadsDetailData={leadsListData} hasSearch={hasSearch} />
          )}
        </MainLayout>
      </LayoutWrapper>
    </RequireAuth>
  )
}

export default LeadsList
