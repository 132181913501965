import { styled } from '@creditas/stylitas'
import { Typography } from '@creditas/typography'

const ContractStatusValue = styled(Typography)`
  color: #1b75dd;
  font-size: 16px;
  font-weight: 400;
`

const FiltersRow = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
`

const StatusSelectDiv = styled.span`
  width: 250px;
`

const ContractCounter = styled.span`
  color: ${({ theme }) => theme.bColorNeutral80};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 24px;
`

const ContractCounterNumber = styled.span`
  color: ${({ theme }) => theme.bColorNeutral80};
  font-size: 16px;
  font-weight: 800;
`

export { ContractStatusValue, FiltersRow, StatusSelectDiv, ContractCounter, ContractCounterNumber }
