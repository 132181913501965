import React from 'react'

const ArrowRightIcon = (props) => {
  return (
    <svg
      {...props}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19 11.9995L5 11.9995'
        stroke='#1F2D27'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14 16.9995L19 11.9995'
        stroke='#1F2D27'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14 6.99951L19 11.9995'
        stroke='#1F2D27'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export { ArrowRightIcon }
