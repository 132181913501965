import { styled } from '@creditas/stylitas'
import { Typography } from '@creditas/typography'
import { ModalBody } from '@creditas-ui/react'

const Title = styled(Typography)`
  color: ${({ theme }) => theme.bColorNeutral90};
  margin-bottom: 40px;
  font-weight: 300;
`

const Name = styled(Typography)`
  color: ${({ theme }) => theme.bColorNeutral90};
  font-weight: 300;
  padding-bottom: 3%;
  span {
    font-weight: 700;
  }
`

const Box = styled.section`
  margin: 50px 0;
  position: relative;
`

const ProposalWrapper = styled(Box)`
  padding-right: 150px;
`

const Content = styled.div`
  padding: 90px 200px 90px 100px;
  margin: 0 30vh;
  height: 100vh;
`

const View = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100% - 94px);
`

const DocDownloadClick = styled.div`
  text-align: -webkit-center;
`

const ModalBodyStyled = styled(ModalBody)`
  padding-top: 50px;
  padding-bottom: 30px;
`

export { Title, Name, Box, ProposalWrapper, Content, View, DocDownloadClick, ModalBodyStyled }
