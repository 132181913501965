import { styled } from '@creditas/stylitas'
import { Typography } from '@creditas/typography'
import { Input } from 'antd'
import {
  ButtonSecondary,
  styled as newStyled,
  selectTheme,
  css,
  ButtonPrimaryTextOnly,
} from '@creditas-ui/react'

export const Aside = styled.aside`
  width: 35vw;
  padding-left: 90px;
  padding-top: 85px;

  h4 {
    padding: 0 30px;
  }
`

export const Content = styled.div`
  padding: 90px 200px 90px 100px;
  margin: 0 35vh;
  height: 100vh;
`

export const View = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100% - 94px);
`

export const Name = styled(Typography)`
  color: ${({ theme }) => theme.bColorNeutral90};
  font-weight: 300;

  span {
    font-weight: 700;
  }
`

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.bColorNeutral90};
  margin-bottom: 40px;
  font-weight: 300;
`

export const Box = styled.section`
  margin: 50px 0;
  position: relative;
`

export const ProposalWrapper = styled(Box)`
  padding-right: 150px;
`

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 20% 32px;
`

export const Label = styled(Typography)`
  color: ${({ theme }) => theme.bColorNeutral60};
  font-size: 16px;
  font-weight: 400;
`

export const Value = styled(Typography)`
  color: ${({ theme }) => theme.bColorNeutral90};
  font-size: 16px;
  font-weight: 600;
`

export const FeedItem = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 400;
  margin-bottom: 48px;
`

export const FeedItemTitle = styled.p`
  color: ${({ theme }) => theme.bColorNeutral90};
  font-size: 16px;
  line-height: 24px;
`

export const FeedItemTime = styled.p`
  color: ${({ theme }) => theme.bColorNeutral90};
  font-size: 14px;
  line-height: 20px;
`

export const FeedItemDescription = styled.p`
  color: ${({ theme }) => theme.bColorNeutral60};
  font-size: 16px;
  line-height: 24px;
  margin-top: 12px;
`

export const StepCard = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${(props) => (props.current ? ({ theme }) => theme.bColorNeutral15 : 'none')};
  padding: 25px;

  svg {
    margin-right: 10px;
  }
`

export const StepContent = styled.div`
  width: 60%;
`

export const StepTitle = styled.p`
  color: #1f2d27;
  font-size: 20px;
  line-height: 28px;
  margin-top: ${(props) => (props.hasStarted ? '0px' : '5px')};
`

export const StepSubtitle = styled.p`
  color: #6f7974;
  font-size: 16px;
  line-height: 24px;
  max-width: 70%;
`

export const ErrorContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const FeedAction = styled(ButtonSecondary)`
  padding: 0;
  margin-top: 28px;
`

export const CustomScroll = newStyled.div`
  background-color: ${selectTheme('colors.neutral.10')};
  border-radius: 9px;
  position: relative;
  z-index: 2;
`

export const showFade = ({ show }) =>
  show &&
  css`
    display: block;
  `

export const Fade = newStyled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 150px;
  background: linear-gradient(
    360deg,
    #ffffff 30%,
    rgba(255, 255, 255, 0) 134.17%
  );
  opacity: ${({ show }) => (show ? 1 : 0)};
  z-index: 1;
  display: none;
  ${showFade}
`

export const CancellButton = styled(ButtonPrimaryTextOnly)`
  color: #fff;
  background-color: #dc3838;
  width: 150px;
`

export const RequestDocuments = styled(ButtonPrimaryTextOnly)`
  color: #fff;
  background-color: #969b98;
  width: 150px;
`

export const ButtonDocs = styled.button`
  background-color: #fff;
  border: 0;
  cursor: pointer;
  color: #ff7711;
  font-size: 22px;
`

export const LabelDocs = styled.label`
  padding-left: 7px;
  padding-right: 30px;
  font-size: 15px;
`

export const DocsCard = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px 16px 16px 8px;
  border: solid 1px #eeeeee;
  border-radius: 4px;
  background: #ffffff;
  position: relative;
  overflow: hidden;
  /* border-left: 8px solid #ff7711; */

  svg {
    padding-right: 10px;
    width: 40px;
    height: 40px;
    color: #49e295;
    cursor: pointer;
  }
`
export const InputCurrency = styled(Input)`
  border: '1px solid #ccc';
  width: '100%' !important;
  border-radius: '5px';
  height: '45px';
  padding: '10px';
  font-size: 16px;
`
