import { styled } from '@creditas/stylitas'
import { Avatar } from '@creditas/avatar'
import { styled as systemStyled, getTypography, selectTheme } from '@creditas-ui/react'

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: row;
`

const ProfileOptions = styled.div`
  display: 'block';
  margin-top: ${(props) => (props.expandedOptions ? '20px' : '0px')};
  height: 0px;
  overflow: hidden;
  transition: height 0.2s ease-in-out;
  transition: margin 0.2s ease-in-out;

  li {
    list-style: none;
  }
`

const ProfileContainer = styled.div`
  padding: 0 20px;
`

const Role = styled.span`
  color: ${({ theme }) => theme.bColorNeutral60};
  font-size: 14px;
  font-weight: 400;
`

const ProfileButton = styled.button`
  background: none;
  border: none;
  outline-color: ${({ theme }) => theme.bColorNeutralWhite};
  cursor: pointer;
  margin-left: 2px;

  svg {
    vertical-align: middle;
  }
`

const NameContainer = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.bColorNeutral90};
  font-size: 16px;
  font-weight: 400;
  padding-top: ${(props) => (props.hasRole ? '0px' : '10px')};
`

const CustomAvatar = styled(Avatar)`
  background-color: ${({ theme }) => theme.bColorNeutral15};
  border-color: ${({ theme }) => theme.bColorNeutral15};
  color: ${({ theme }) => theme.bColorNeutral80};
`

const Link = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.bColorNeutral90};
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  padding-left: 0;
  padding-top: 8px;
`

const SourceTitle = systemStyled.p`
  font-style: normal;
  ${getTypography('bodySmMedium')};
  color: ${selectTheme('colors.neutral.60')};
`

const SourceSubtitle = systemStyled.p`
  font-style: normal;
  ${getTypography('bodySmRegular')};
  color: ${selectTheme('colors.neutral.60')};
`

export {
  FooterContainer,
  ProfileOptions,
  ProfileContainer,
  Role,
  ProfileButton,
  NameContainer,
  CustomAvatar,
  Link,
  SourceTitle,
  SourceSubtitle,
}
