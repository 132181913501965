import { styled } from '@creditas/stylitas'

const ListItem = styled.li`
  display: flex;
  padding: 15px 0 15px 8px;
  list-style: none;
`

const Link = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  color: ${(props) =>
    props.active
      ? ({ theme }) => theme.bColorPrimaryDefault
      : ({ theme }) => theme.bColorNeutral90};

  svg path {
    stroke: ${(props) =>
      props.active
        ? ({ theme }) => theme.bColorPrimaryDefault
        : ({ theme }) => theme.bColorNeutral90};
  }

  &:hover {
    color: ${({ theme }) => theme.bColorPrimaryDefault};
  }

  &:hover svg path {
    stroke: ${({ theme }) => theme.bColorPrimaryDefault};
  }
`
const MenuIconContainer = styled.div`
  height: 32px;
  width: 32px;
`

export { ListItem, Link, MenuIconContainer }
