import { ButtonPrimaryTextOnly, IconDeleteDisabled } from '@creditas-ui/react'
import { useEffect, useState } from 'react'
import { getDocBlob, getDocData, postContractAnalysis } from '../../../api/api'
import {
  CreditasDocViewerStyled,
  CreditasFooter,
  CreditasHeader,
  ModalDocViewerStyled,
  TextDivLeft,
  PageWrapper,
  CloseIconStyled,
  PdfContainer,
  ImageContainer,
  Image,
  ButtonView,
  ButtonPadding,
  StyledTextArea,
  ModalTitle,
  ModalSubtitle,
  ModalBodyView,
} from './ModalDocViewer.style'
import { Page } from 'react-pdf'
import { ButtonDocs } from '../../../presentation/Lead/Lead.styles'
import { ContractViewerLabel } from '../../../presentation/Lead/components/PersonItens/PersonItens.styles'
import AnalysisModal from './components/AnalysisModal'
import { toast } from 'react-toastify'

export const ModalDocViewer = ({
  viewContractBool,
  idDoc,
  title,
  hasValidationButtons = false,
  idOlx = '',
  closeModalDocViewer = () => {},
}) => {
  const [doc, setDoc] = useState([])
  const [numPages, setNumPages] = useState(null)
  const [extension, setExtension] = useState(null)

  const [rejectDescription, setRejectDescription] = useState('')
  const [rejectModalIsOpen, setRejectModalIsOpen] = useState(false)
  const [approveModalIsOpen, setApproveModalIsOpen] = useState(false)
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false)
  const [sucessModalTexts, setSucessModalTexts] = useState({
    title: '',
    subtitle: '',
  })

  const contractAnalysis = async (analysisResult) => {
    try {
      let requestData = {}
      if (analysisResult === 'REJECTED') {
        setSucessModalTexts({
          title: 'Contrato pendenciado',
          subtitle: 'O contrato foi pendenciado com sucesso.',
        })

        requestData = {
          result: analysisResult,
          rejectedDesc: rejectDescription,
        }
      } else {
        setSucessModalTexts({
          title: 'Contrato validado',
          subtitle: 'O contrato foi validado com sucesso.',
        })

        requestData = {
          result: analysisResult,
        }
      }

      await postContractAnalysis(idOlx, requestData)
      setSuccessModalIsOpen(true)
    } catch (error) {
      console.log(error)
      toast.error('Ocorreu um erro inesperado! Atualize a página e tente novamente.')
    }
  }

  async function callDocData(id) {
    const response = await getDocData(id)
    const docData = response.data
    const responseBlob = await getDocBlob(id)
    setExtension(docData?.details?.extension?.toLowerCase())
    const type = responseBlob.headers['content-type']
    const fileBlob = new Blob([responseBlob?.data], {
      type: type,
      encoding: 'UTF-8',
    })
    setDoc(fileBlob)
  }

  useEffect(() => {
    callDocData(idDoc)
  }, [idDoc])

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  return (
    <>
      <AnalysisModal
        isOpen={rejectModalIsOpen}
        title='Contrato'
        closeModal={() => setRejectModalIsOpen(false)}
        onPrimaryButtonClick={() => {
          setRejectModalIsOpen(false)
          contractAnalysis('REJECTED')
        }}
        allowClose
        size='medium'
        primaryButtonLabel='Pendenciar contrato'
      >
        <ModalBodyView>
          <ModalTitle>Pendenciar contrato?</ModalTitle>
          <br />
          <ModalSubtitle>Deseja seguir pendenciando o contrato?</ModalSubtitle>
          <ModalSubtitle>Caso houver alguma observação informa no campo abaixo:</ModalSubtitle>
          <br />
          <StyledTextArea
            id='InputTextArea'
            label='Digite aqui sua observação...'
            value={rejectDescription}
            onChange={(e) => setRejectDescription(e.target.value)}
          />
        </ModalBodyView>
      </AnalysisModal>

      <AnalysisModal
        isOpen={approveModalIsOpen}
        title='Contrato'
        closeModal={() => setApproveModalIsOpen(false)}
        onPrimaryButtonClick={() => {
          setApproveModalIsOpen(false)
          contractAnalysis('APPROVED')
        }}
        allowClose
        size='medium'
        primaryButtonLabel='Validar contrato'
      >
        <ModalBodyView>
          <ModalTitle>Validar contrato?</ModalTitle>
          <br />
          <ModalSubtitle>Deseja seguir com a validação do contrato?</ModalSubtitle>
        </ModalBodyView>
      </AnalysisModal>

      <AnalysisModal
        isOpen={successModalIsOpen}
        title='Contrato'
        onPrimaryButtonClick={() => closeModalDocViewer(false)}
        allowClose={false}
        size='medium'
        primaryButtonLabel='Ok entendi'
        secondaryButtonLabel={null}
      >
        <ModalBodyView>
          <ModalTitle>{sucessModalTexts.title}</ModalTitle>
          <br />
          <ModalSubtitle>{sucessModalTexts.subtitle}</ModalSubtitle>
        </ModalBodyView>
      </AnalysisModal>

      <ModalDocViewerStyled>
        <CreditasHeader>
          <TextDivLeft>Contrato - {title}</TextDivLeft>
          <CloseIconStyled
            Icon={IconDeleteDisabled}
            size='large'
            onClick={() => {
              viewContractBool(false)
            }}
          />
        </CreditasHeader>
        {extension === 'pdf' && (
          <PdfContainer id='pdf-container'>
            <CreditasDocViewerStyled file={doc} onLoadSuccess={onDocumentLoadSuccess}>
              <PageWrapper>
                {Array.apply(null, Array(numPages))
                  .map((x, i) => i + 1)
                  .map((page, index) => (
                    <div key={index} style={{ marginTop: '1%' }}>
                      <Page pageNumber={page} scale={2} />
                    </div>
                  ))}
              </PageWrapper>
            </CreditasDocViewerStyled>
          </PdfContainer>
        )}
        {extension !== 'pdf' && extension !== null && (
          <ImageContainer>
            <Image alt='' src={URL.createObjectURL(doc)} />
          </ImageContainer>
        )}
        <CreditasFooter>
          {hasValidationButtons && (
            <ButtonView>
              <ButtonPadding>
                <ButtonDocs onClick={() => setRejectModalIsOpen(true)}>
                  <ContractViewerLabel>Pendenciar contrato</ContractViewerLabel>
                </ButtonDocs>
              </ButtonPadding>
              <ButtonPadding>
                <ButtonPrimaryTextOnly onClick={() => setApproveModalIsOpen(true)}>
                  Validar contrato
                </ButtonPrimaryTextOnly>
              </ButtonPadding>
            </ButtonView>
          )}
        </CreditasFooter>
      </ModalDocViewerStyled>
    </>
  )
}
