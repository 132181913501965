import { useEffect, useState } from 'react'
import { Form } from 'antd'
import { ButtonPrimaryTextOnly } from '@creditas-ui/react'

import { useNavigate } from 'react-router-dom'
import { Container, ContainerArea, ContainerLogo, Logo, InputLogin } from './login.styles'

import logo from '../../assets/img/logo-creditas.svg'

import './signin.css'
import { toast } from 'react-toastify'
import { destroyCredentials, storeCredentials } from '../../infrastructure/authentication'
import { postUserLogin } from '../../api/api'

import jwt_decode from 'jwt-decode'

export const Login = () => {
  const tokenLocalStorage = localStorage.getItem('home-solutions-frontend')

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [token, setToken] = useState(null)

  const errorInvalidLoginMsg = 'Não foi possível efetuar o login. Verifique seu email e/ou senha.'
  const customErrorToast = (msg) => {
    toast.error(msg, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: 'colored',
      bodyStyle: { width: '100px' },
    })
  }
  const navigate = useNavigate()

  if (token) {
    const tokenUser = jwt_decode(token)

    storeCredentials(token)
    localStorage.setItem('username', JSON.stringify(tokenUser.username))
  }

  const signIn = async (username, password) => {
    try {
      setLoading(true)
      const myHeaders = new Headers()
      myHeaders.append('Access-Control-Allow-Origin', '*')
      const formdata = new FormData()
      formdata.append('username', `${username}`)
      formdata.append('password', `${password}`)
      formdata.append('grant_type', 'password')

      const response = await postUserLogin({
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
      })

      await response
        .json()
        .then((response) => {
          const token = response.access_token
          if (token) {
            storeCredentials(token)
            window.location = '/dashboard'
          } else {
            customErrorToast(errorInvalidLoginMsg)
          }
        })
        .catch((err) => {
          console.log(err)
          navigate('/')
          customErrorToast(errorInvalidLoginMsg)
          destroyCredentials()
        })
    } catch (error) {
      console.log(error)
      customErrorToast(errorInvalidLoginMsg)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (tokenLocalStorage) {
      setToken(tokenLocalStorage)
      navigate('/dashboard')
    } else {
      navigate('/')
    }
  }, [navigate, tokenLocalStorage, setToken])

  function handleSubmit() {
    if (username !== '' && password !== '') {
      return signIn(username, password, navigate)
    }

    toast.warn('Os campos de E-mail e Senha devem ser preenchidos para efetuar o login.')
  }

  return (
    <Container>
      <ContainerArea>
        <ContainerLogo>
          <Logo src={logo} alt='Logo Sistema' />
        </ContainerLogo>

        <Form>
          <Form.Item name='email' style={{ fontSize: '20px' }}>
            <InputLogin
              value={username}
              placeholder='E-mail'
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Item>

          <Form.Item name='password'>
            <InputLogin
              value={password}
              placeholder='Senha'
              onChange={(e) => setPassword(e.target.value)}
              type='password'
            />
          </Form.Item>

          <ButtonPrimaryTextOnly
            onClick={() => handleSubmit()}
            loading={loading}
            variant='primary'
            style={{
              width: '100%',
              height: '60px',
              fontSize: '19px',
              color: '#FFF',
              fontWeight: 'bold',
            }}
          >
            Entrar
          </ButtonPrimaryTextOnly>
        </Form>
      </ContainerArea>
    </Container>
  )
}
