const colorPalette = {
  bColorPrimaryLight: '#49E295',
  bColorPrimaryDefault: '#11BB77',
  bColorPrimaryDark: '#0F7C50',
  bColorSecondaryLight: '#97DAFF',
  bColorSecondaryDefault: '#1B75DD',
  bColorSecondaryDark: '#2159A3',
  bColorNeutralWhite: '#FFFFFF',
  bColorNeutral10: '#EDF0EF',
  bColorNeutral15: '#DBE1DD',
  bColorNeutral20: '#C7CEC9',
  bColorNeutral40: '#A5AEA7',
  bColorNeutral60: '#6F7974',
  bColorNeutral80: '#596B64',
  bColorNeutral90: '#1F2D27',
  bColorSuccess: '#11BB77',
  bColorWarning: '#EC8308',
  bColorError: '#DE1212',
  bColorInfo: '#3D9C96',
}

export { colorPalette }
