function ContractCheck(props) {
  return (
    <svg
      width='24'
      height='25'
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M16.5 22.8333C15.9091 22.8333 15.3239 22.7169 14.7779 22.4907C14.232 22.2646 13.7359 21.9331 13.318 21.5152C12.9002 21.0974 12.5687 20.6013 12.3425 20.0553C12.1164 19.5094 12 18.9242 12 18.3333C12 17.7423 12.1164 17.1571 12.3425 16.6112C12.5687 16.0652 12.9002 15.5691 13.318 15.1513C13.7359 14.7334 14.232 14.4019 14.7779 14.1758C15.3239 13.9496 15.9091 13.8333 16.5 13.8333C17.6935 13.8333 18.8381 14.3074 19.682 15.1513C20.5259 15.9952 21 17.1398 21 18.3333C21 19.5267 20.5259 20.6713 19.682 21.5152C18.8381 22.3591 17.6935 22.8333 16.5 22.8333'
        stroke='#23A969'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18 10.8333V8.66125C17.9999 8.13086 17.7891 7.62224 17.414 7.24725L14.586 4.41925C14.211 4.04415 13.7024 3.83337 13.172 3.83325H6C5.46957 3.83325 4.96086 4.04397 4.58579 4.41904C4.21071 4.79411 4 5.30282 4 5.83325V19.8333C4 20.3637 4.21071 20.8724 4.58579 21.2475C4.96086 21.6225 5.46957 21.8333 6 21.8333H10'
        stroke='#23A969'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18 8.83325H14C13.7348 8.83325 13.4804 8.72789 13.2929 8.54036C13.1054 8.35282 13 8.09847 13 7.83325V3.83325'
        stroke='#23A969'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.1568 17.5054L16.0848 19.5774L14.8428 18.3334'
        stroke='#23A969'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export { ContractCheck }
