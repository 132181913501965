import React, { useContext } from 'react'
import { node } from 'prop-types'
import { compose, withDefaultProps, withForwardRef } from '@creditas/utils'
import { styled } from '@creditas/stylitas'
import { Grid, GridItem } from '@creditas/layout'
import { CloseIcon } from '../Icons'
import { ModalContext } from './ModalContext'
import { modalBody, modalButtonClose, modalOverlay, modalContent } from './modal.style'

const ModalOverlay = styled.div`
  ${modalOverlay};
`
const ModalBody = styled.div`
  ${modalBody};
`
const ModalContent = styled.div`
  ${modalContent};
`

const ModalButtonClose = styled.button`
  ${modalButtonClose};
`

const propTypes = {
  children: node.isRequired,
}

const defaultProps = {
  component: 'div',
}

const gridOptions = {
  gridAreas: {
    xs: `
      "${'modalBody '.repeat(12)}"
    `,
    md: `"${'modalOverlayArea '.repeat(7)} ${'modalBody '.repeat(5)} "`,
  },
}

const enhancer = compose(withForwardRef, withDefaultProps(propTypes, defaultProps))

const element = enhancer(function Modal(props) {
  const { handleModalClose, modalOpen } = useContext(ModalContext)
  const { testId, withRef, className, children, modalBodyProps, ...other } = props

  return (
    modalOpen && (
      <div
        data-testid={testId}
        ref={withRef}
        className={className}
        role='dialog'
        aria-modal='true'
        {...other}
      >
        <Grid options={gridOptions}>
          <GridItem area='modalBody'>
            <ModalBody style={modalBodyProps?.style}>
              <ModalContent>
                <ModalButtonClose aria-label='Fechar' onClick={() => handleModalClose()}>
                  <CloseIcon aria-hidden={true} />
                </ModalButtonClose>
                {children}
              </ModalContent>
            </ModalBody>
            <ModalOverlay onClick={() => handleModalClose()} />
          </GridItem>
          <GridItem area='modalOverlayArea' />
        </Grid>
      </div>
    )
  )
})

export { element as Modal }
