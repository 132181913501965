import 'moment/locale/pt-br'
import { Table, TableHead, TableRow, TableCell } from '../../../shared/components/Table'
import moment from 'moment'
import { ContractStatusValue } from '../LeadContractsList/LeadContractsList.styles'
import { useNavigate } from 'react-router-dom'

const contractFormatMap = {
  DIGITAL: 'Digital',
  SCANNED: 'Escaneado',
  INVALID: 'Inválido',
}

const ContractsTable = ({ contractsData }) => {
  moment.locale('pt-br')

  const navigate = useNavigate()

  function handleClick(id) {
    navigate(`/contract/${id}`)
  }

  return (
    <>
      <Table>
        <TableHead
          headings={[
            ' Lead ',
            ' Proponente ',
            ' Imobiliária ',
            ' Status do contrato ',
            'Formato do Documento',
            'Match (%)',
          ]}
        />
        <tbody>
          {contractsData?.map((contractItem, index) => (
            <TableRow id={index} key={index} onClick={() => handleClick(contractItem.olxId)}>
              <TableCell>{contractItem?.lead}</TableCell>
              <TableCell>
                {contractItem?.proponents.map((proponent, pIndex) => (
                  <span key={pIndex}>
                    {proponent}
                    <br />
                  </span>
                ))}
              </TableCell>
              <TableCell>{contractItem?.realEstate}</TableCell>
              <TableCell>
                <ContractStatusValue>{contractItem?.contractStatusDesc}</ContractStatusValue>
              </TableCell>
              <TableCell>{contractFormatMap[contractItem?.contractFormatType]}</TableCell>
              <TableCell>{contractItem?.matchPercentage ?? 'N/A'}</TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </>
  )
}

export { ContractsTable }
