import currency from 'currency.js'

export const formatMoney = (number) => {
  if (!number || typeof number !== 'number') return null
  return currency(number, {
    symbol: 'R$ ',
    decimal: ',',
    separator: '.',
  }).format()
}
