import 'react-toastify/dist/ReactToastify.css'
import './index.css'
import { createRoot } from 'react-dom/client'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'

import App from './App'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(<App tab='root' />)

datadogRum.init({
  applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: process.env.REACT_APP_DATADOG_SERVICE,
  env: process.env.NODE_ENV,
  version: process.env.REACT_APP_DATADOG_VERSION,
  sampleRate: 100,
  sessionReplaySampleRate: 20,
  trackInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
})

datadogRum.startSessionReplayRecording()

datadogLogs.init({
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  version: process.env.REACT_APP_DATADOG_VERSION,
  service: process.env.REACT_APP_DATADOG_SERVICE,
  site: 'datadoghq.com',
  forwardErrorsToLogs: true,
  sampleRate: 100,
})
