import React from 'react'
import { styled } from '@creditas/stylitas'
import { compose, withDefaultProps, withForwardRef } from '@creditas/utils'

const TableStyle = styled.table`
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  background-color: ${({ theme }) => theme.bColorNeutralWhite};
`

const propTypes = {}

const defaultProps = {
  component: 'table',
  fullWidth: true,
}

const enhancer = compose(withForwardRef, withDefaultProps(propTypes, defaultProps))

const element = enhancer(function Table(props) {
  const { fullWidth, ref, component, className, children, ...other } = props

  return (
    <TableStyle ref={ref} as={component} className={className} fullWidth={fullWidth} {...other}>
      {children}
    </TableStyle>
  )
})

export { element as Table }
