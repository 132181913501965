import { Input } from 'antd'

const { Search } = Input

export const SearchBar = ({ onSearchButtonSubmit, onChange, onSubmit }) => {
  return (
    <form onSubmit={onSubmit} style={{ width: '100%' }}>
      <div>
        <Search
          type='text'
          size='large'
          onChange={onChange}
          onSearch={onSearchButtonSubmit}
          className='form-control'
          id='searchTerm'
          placeholder='Buscar leads por CPF completo ou pelo Id OLX'
        />
      </div>
    </form>
  )
}
