import { styled } from '@creditas/stylitas'
import { Collapse, Divider } from 'antd'
import { Typography } from '@creditas/typography'

const StyledCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10vh;
`

const TextDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const LoadingDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10vh;
  padding-bottom: 2vh;
  svg {
    width: 60px;
    height: 60px;
  }
`

const StyledCollapse = styled(Collapse)`
  width: 850px;
  background-color: #fff;
  font-size: 18px;
  margin: 0 20%;
`

const StyledDivider = styled(Divider)`
  background-color: #000;
  width: 2px;
`

const CollapseItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 20% 32px;
`

const Label = styled(Typography)`
  color: ${({ theme }) => theme.bColorNeutral60};
  font-size: 16px;
  font-weight: 400;
`

const Value = styled(Typography)`
  color: ${({ theme }) => theme.bColorNeutral90};
  font-size: 16px;
  font-weight: 600;
`

const Name = styled(Typography)`
  color: ${({ theme }) => theme.bColorNeutral90};
  font-weight: 300;

  span {
    font-weight: 700;
  }
`

export {
  StyledCenter,
  TextDiv,
  LoadingDiv,
  StyledCollapse,
  StyledDivider,
  CollapseItem,
  Item,
  Label,
  Value,
  Name,
}
