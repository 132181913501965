const FEED_ITEM_TITLE = {
  REJECTED: 'A proposta foi negada',
  CONTACT_SUCCESS: 'Contato com cliente bem sucedido',
  REVIVAL: 'A proposta foi reativada',
}

const FLAG_FEED_ITEM_TITLE = {
  ...FEED_ITEM_TITLE,
  DOCUMENT_REQUIRED: 'Documentos para análise está pendente',
  INSPECTION_CREATED: 'Vistoria do veículo iniciada',
  INSPECTION_RETRIED: 'Vistoria nova do veículo iniciada',
  INSPECTION_FINALIZED: 'Vistoria do veículo finalizada',
  INSPECTION_REFUSED: 'Vistoria do veículo recusada',
  DOCUMENTS_SENT: 'Documentos enviados para análise',
}

const FEED_ITEM_DESCRIPTION = {
  PROPOSAL_REJECTED: 'A proposta foi desconsiderada após análise',
  PROPOSAL_REVIVAL: 'A proposta foi reativada',
  CLOSED_WITH_COMPETITOR: 'Cliente optou pela concorrência',
  CONTACT_WITHOUT_SUCCESS: 'Várias tentativas de contato sem sucesso',
  DROP_OUT: 'Cliente desistiu da proposta',
  OUT_OF_POLICY: 'A proposta está fora da política',
  OUT_OF_POLICY_COLLATERAL: 'A garantia está fora da política',
  OUT_OF_POLICY_CREDIT: 'A proposta está fora da política de crédito',
  OUT_OF_POLICY_VEHICLE_MODEL: 'A garantia está fora da política',
  OUT_OF_POLICY_VEHICLE_YEAR: 'A garantia está fora da política',
}

export { FEED_ITEM_TITLE, FEED_ITEM_DESCRIPTION, FLAG_FEED_ITEM_TITLE }
