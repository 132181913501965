import React from 'react'
import { styled } from '@creditas/stylitas'
import { styled as newStyled, selectTheme } from '@creditas-ui/react'
import { Typography } from '@creditas/typography'
import { Card } from './Card/Card'

export const LayoutWrapper = styled.section`
  display: flex;
  align-items: center;
`

export const Hero = styled.div`
  display: flex;
  grid-template-columns: auto 184px;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
`

export const Title = styled(Typography)`
  font-weight: 300;
`

export const StepWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Step = newStyled(Typography)`
  color: ${selectTheme('colors.neutral.80')};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`

export const NewProposalTitle = styled.h1`
  color: ${({ theme }) => theme.bColorNeutral90};
  font-weight: 300;
  font-size: 32px;
  line-height: 48px;
  padding: 0 7vw;
  margin-bottom: 64px;
`

export const CustomCard = styled((props) => <Card {...props} />)`
  &:nth-of-type(1) {
    border-bottom: 0;
  }
  svg {
    transition: 0.3s;
  }
  &:hover {
    svg {
      position: absolute;
      right: calc(8vw + -12px);
    }
  }
`

export const NewIncomeAnslysisNoticeDiv = styled.div`
  padding: 10px;
  margin-left: 20%;
  margin-right: 20%;
  background-color: #49e295;
  border-radius: 30px;
  justify-content: center;
  display: flex;
`

export const NewIncomeAnslysisNoticeText = styled.div`
  padding: 10px;
  font-size: 16px;
`
