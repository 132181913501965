import { styled } from '@creditas/stylitas'
import { Typography } from '@creditas/typography'

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25vh 0;
`

export const ErrorTitle = styled(Typography)`
  text-align: center;
  color: ${({ theme }) => theme.bColorNeutral90};
  font-weight: 400;
  font-size: 24px;
`

export const ErrorDescription = styled(Typography)`
  text-align: center;
  color: ${({ theme }) => theme.bColorNeutral60};
  font-weight: 400;
  font-size: 16px;
`
