import { useEffect, useState } from 'react'
import { Header, LeadsList } from '../../shared/components'
import {
  LayoutWrapper,
  NewIncomeAnslysisNoticeDiv,
  NewIncomeAnslysisNoticeText,
} from '../../shared/components/Home.styles'
import { MainLayout } from '../../shared/components/MainLayout/MainLayout'
import { Error } from '../../shared/components/Error/Error'
import { StyledCenterIcon } from '../../shared/components/Document/Document.style'
import { Loading } from '../../shared/components/Icons'
import { getLeadsList, logout } from '../../api/api'
import { RequireAuth } from '../../utils/requiredAuthValidation'
import { configureAbly, useChannel } from '@ably-labs/react-hooks'
import { AblyApiKey } from '../../infrastructure/apikeys/apikeys'
import { IconBellWarning } from '@creditas-ui/react'
import { GlobalIcons } from '@creditas-ui/react'

const Home = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [leadsData, setLeadsData] = useState([])
  const [hasNewIncomeAnalysis, setHasNewIncomeAnalysis] = useState(false)

  configureAbly({ key: AblyApiKey })
  useChannel('home-solutions', () => {
    setHasNewIncomeAnalysis(true)
  })

  const loadLeadListData = async () => {
    try {
      setError(false)
      setLoading(true)
      const response = await getLeadsList()
      setLeadsData(response.data)
    } catch (error) {
      setError(true)
      if (error?.response?.status === 401) {
        logout()
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadLeadListData()
  }, [])

  if (loading) {
    return (
      <StyledCenterIcon>
        <Loading />
      </StyledCenterIcon>
    )
  }

  return (
    <RequireAuth navigationRoute={'/'}>
      <LayoutWrapper>
        <MainLayout>
          <Header title='GreyZone - Análise de Credito' />
          {hasNewIncomeAnalysis && (
            <NewIncomeAnslysisNoticeDiv>
              <NewIncomeAnslysisNoticeText>
                Existem novas análises de renda disponíveis. Atualize a página para visualizá-las.
              </NewIncomeAnslysisNoticeText>
              <GlobalIcons Icon={IconBellWarning} size='x_large' />
            </NewIncomeAnslysisNoticeDiv>
          )}
          {!loading && !error && (
            <>{(leadsData?.length >= 0) | (leadsData === '') && <LeadsList leads={leadsData} />}</>
          )}
          {!loading && error && <Error />}
        </MainLayout>
      </LayoutWrapper>
    </RequireAuth>
  )
}

export default Home
