import React from 'react'
import { node } from 'prop-types'

const propTypes = {
  children: node.isRequired,
}

const ModalContext = React.createContext({})

export const ModalProvider = ({ children }) => {
  const [modalOpen, setModalOpen] = React.useState(false)

  function handleModalOpen() {
    setModalOpen(true)
  }

  function handleModalClose() {
    setModalOpen(false)
  }

  return (
    <ModalContext.Provider
      value={{
        modalOpen,
        handleModalOpen,
        handleModalClose,
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}

ModalProvider.propTypes = propTypes

export { ModalContext }
