import { useEffect, useState } from 'react'
import { Header } from '../../../shared/components'
import { LayoutWrapper } from '../../../shared/components/Home.styles'
import { MainLayout } from '../../../shared/components/MainLayout/MainLayout'
import { Error } from '../../../shared/components/Error/Error'
import { StyledCenterIcon } from '../../../shared/components/Document/Document.style'
import { Loading } from '../../../shared/components/Icons'
import { getPendingContracts, logout } from '../../../api/api'
import { RequireAuth } from '../../../utils/requiredAuthValidation'
import { ContractsTable } from '../components/ContractsTable'
import { Select } from '@creditas-ui/select'
import {
  ContractCounter,
  ContractCounterNumber,
  FiltersRow,
  StatusSelectDiv,
} from './LeadContractsList.styles'

const statusOptions = [
  {
    label: 'Aguardando Validação',
    value: 'PENDING_VALIDATION',
  },
  {
    label: 'Aprovado',
    value: 'APPROVED',
  },
  {
    label: 'Pendenciado',
    value: 'REJECTED',
  },
]

const statusFormatMap = {
  REJECTED: 'Pendenciado',
  APPROVED: 'Aprovado',
  PENDING_VALIDATION: 'Aguardando Validação',
}

const LeadContractsList = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [leadContractsData, setLeadContractsData] = useState([])
  const [contractStatus, setContractStatus] = useState('PENDING_VALIDATION')

  const loadLeadContractListData = async () => {
    try {
      setError(false)
      setLoading(true)
      const response = await getPendingContracts(contractStatus)
      console.log(response)
      setLeadContractsData(response.data)
    } catch (error) {
      setError(true)
      if (error?.response?.status === 401) {
        logout()
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadLeadContractListData()
  }, [contractStatus])

  if (loading) {
    return (
      <StyledCenterIcon>
        <Loading />
      </StyledCenterIcon>
    )
  }

  return (
    <RequireAuth navigationRoute={'/'}>
      <LayoutWrapper>
        <MainLayout>
          <Header title='Contratos' />
          <FiltersRow>
            <ContractCounter>
              Total de Contratos:{' '}
              <ContractCounterNumber>{leadContractsData?.length}</ContractCounterNumber>
            </ContractCounter>
            <StatusSelectDiv>
              <Select
                onChange={(value) => setContractStatus(value)}
                options={statusOptions}
                placeholder={statusFormatMap[contractStatus]}
              />
            </StatusSelectDiv>
          </FiltersRow>
          {!loading && !error && (
            <>
              {(leadContractsData?.length >= 0) | (leadContractsData === '') && (
                <ContractsTable contractsData={leadContractsData} />
              )}
            </>
          )}
          {!loading && error && <Error />}
        </MainLayout>
      </LayoutWrapper>
    </RequireAuth>
  )
}

export default LeadContractsList
