import * as React from 'react'
import { styled, keyframes } from '@creditas-ui/react'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  display: flex;
  align-itens: center;
  justify-content: center;
  animation: ${rotate} 1s linear infinite;
`

const LoadingIcon = () => (
  <svg
    width='20px'
    height='20px'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx={12} cy={12} r={9.5} stroke='#DBE1DD' />
    <mask id='Loading_svg__a' fill='#fff'>
      <path d='M10.648 2.092a10 10 0 0110.594 6.09l-.913.377a9.012 9.012 0 00-9.548-5.488l-.133-.98z' />
    </mask>
    <path
      d='M10.648 2.092a10 10 0 0110.594 6.09l-.913.377a9.012 9.012 0 00-9.548-5.488l-.133-.98z'
      stroke='#1F2D27'
      strokeWidth={2}
      mask='url(#Loading_svg__a)'
    />
  </svg>
)

const Loading = () => (
  <Spinner data-testid='loading'>
    <LoadingIcon />
  </Spinner>
)

export { Loading }
