import { ModalRoot, ModalHeader, ModalBody, ModalFooter } from '@creditas-ui/modal'

const ModalDocs = ({ isOpen, openModal, closeModal, title, onPrimaryButtonClick }) => {
  return (
    <>
      <ModalRoot
        isOpen={isOpen}
        closeModal={closeModal}
        openModal={openModal}
        allowClose
        size='medium'
      >
        <ModalHeader title={title} />

        <ModalBody></ModalBody>
        <ModalFooter
          primaryLabel='Aprovar'
          onPrimaryButtonClick={onPrimaryButtonClick}
          secondaryLabel='Cancelar'
          onSecondaryButtonClick={closeModal}
        />
      </ModalRoot>
    </>
  )
}

export default ModalDocs
