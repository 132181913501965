import 'moment/locale/pt-br'
import { Table, TableHead, TableRow, TableCell } from './Table'

import { useNavigate } from 'react-router-dom'
import { formatCpf } from './helpers/formatCpf'
import { formatMoney } from './helpers/formatMoney'
import moment from 'moment'

import { StatusLabel } from '@creditas-ui/react'

const LeadsList = ({ leads }) => {
  moment.locale('pt-br')

  let navigate = useNavigate()

  function handleClick(id) {
    navigate(`/lead/${id}`)
  }

  return (
    <>
      <Table>
        <TableHead
          headings={[' Nome ', ' CPF ', ' Total do Pacote ', ' Entrada ', ' Status ', 'Consultor']}
        />
        <tbody>
          {leads?.map((leadItem) => (
            <TableRow id={leadItem.id} key={leadItem.id} onClick={() => handleClick(leadItem.id)}>
              <TableCell>{leadItem.lead?.fullName}</TableCell>
              <TableCell>{formatCpf(leadItem.lead?.cpf)}</TableCell>
              <TableCell>{formatMoney(leadItem?.rent)}</TableCell>
              <TableCell>{moment(leadItem?.entry, 'DD-MM-YYYY HH:mm').format('LLL')}</TableCell>
              <TableCell>
                <StatusLabel variant={leadItem.hasActiveContract ? 'warning' : 'neutral'} />
                {leadItem.status === 'IN_MANUAL_ANALYSIS'
                  ? 'Análise Manual' || leadItem.status === 'PENDING_MANUAL_ANALYSIS'
                  : 'Pendente de Análise'}
              </TableCell>
              <TableCell>{leadItem.lastConsultant}</TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </>
  )
}

export { LeadsList }
