import { useEffect, useState } from 'react'
import { MainLayout } from '../../shared/components/MainLayout/MainLayout'
import { Content, Box } from './Lead.styles'

import { Divider } from 'antd'
import { useParams } from 'react-router-dom'
import { StyledCenterIcon } from '../../shared/components/Document/Document.style'
import { Loading } from '../../shared/components/Icons'

import { Steps } from './Steps'
import Header from './components/Header/Header'
import PersonItens from './components/PersonItens/PersonItens'
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from '@creditas-ui/react'
import { RequireAuth } from '../../utils/requiredAuthValidation'
import { getLeadHistory, logout } from '../../api/api'
import { ModalDocViewer } from '../../shared/components/ModalDocViewer/ModalDocViewer'

const Lead = () => {
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [leadHistory, setLeadHistory] = useState([])
  const [viewContract, setViewContract] = useState(false)
  const [modalData, setModalData] = useState({})

  const visibility = (bool) => setViewContract(bool)
  const modalDataFun = (modalDataParam) => setModalData(modalDataParam)

  const loadLeadHistoryData = async () => {
    try {
      setLoading(true)
      const response = await getLeadHistory(id)
      setLeadHistory(response.data)
    } catch (error) {
      if (error?.response?.status === 401) {
        logout()
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadLeadHistoryData(id)
  }, [id])

  if (loading) {
    return (
      <StyledCenterIcon>
        <Loading />
      </StyledCenterIcon>
    )
  }

  return (
    <RequireAuth navigationRoute={'/'}>
      {viewContract && (
        <ModalDocViewer
          idDoc={modalData.docId}
          viewContractBool={visibility}
          title={modalData.title}
        />
      )}
      {
        <MainLayout hasPadding={false}>
          <Box>
            <Content>
              <Header leadId={id} />
              <Divider />
              <PersonItens leadId={id} visibility={visibility} modalDataFun={modalDataFun} />
              <Divider />
              <Accordion>
                <AccordionItem>
                  <AccordionHeader>Histórico</AccordionHeader>
                  <AccordionBody style={{ overflow: 'auto' }}>
                    <Steps leadHistory={leadHistory} />
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
            </Content>
          </Box>
        </MainLayout>
      }
    </RequireAuth>
  )
}

export default Lead
