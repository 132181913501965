import moment from 'moment'
import { Aside } from './Lead.styles'
import { Timeline, Event } from 'react-trivial-timeline'
import { Item, Label, Value } from './Steps.styles'

const Steps = ({ leadHistory }) => {
  const validateHistoryFields = (authorName, description) => {
    if (authorName && authorName !== 'Application') {
      return (
        <>
          <Item>
            <Label>Consultor: </Label>
            <Value>{authorName}</Value>
          </Item>
          {description && (
            <Item>
              <Label>Descrição: </Label>
              <Value>{description}</Value>
            </Item>
          )}
        </>
      )
    }
  }

  return (
    <Aside data-testid='steps'>
      {leadHistory?.map((historyItem, index) => (
        <Timeline key={index} lineColor='black'>
          <Event
            interval={moment(historyItem?.historyDate, 'DD-MM-YYYY HH:mm').format('LLL')}
            title={historyItem?.type === 'APPLICATION' ? 'Log do Sistema' : 'Ação do Consultor'}
          >
            <Item>
              <Label>Status: </Label>
              <Value>{historyItem?.statusDescription}</Value>
            </Item>
            {validateHistoryFields(historyItem?.authorName, historyItem?.description)}
          </Event>
        </Timeline>
      ))}
    </Aside>
  )
}

export { Steps }
