import React from 'react'
import { styled } from '@creditas/stylitas'
import { compose, withDefaultProps, withForwardRef } from '@creditas/utils'

export const MainLayoutStyle = styled.main`
  min-height: 100vh;
  width: 100%;
  margin-left: 94px;
  padding: ${(props) => (props.hasPadding ? '60px 100px' : '0px')};
  transition: margin-left 0.35s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const propTypes = {}

const defaultProps = {
  component: 'main',
  hasPadding: true,
}

const enhancer = compose(withForwardRef, withDefaultProps(propTypes, defaultProps))

const element = enhancer(function MainLayout(props) {
  const { hasPadding, withRef, component, className, children, ...other } = props

  return (
    <MainLayoutStyle
      ref={withRef}
      id='main-layout'
      as={component}
      className={className}
      hasPadding={hasPadding}
      {...other}
    >
      {children}
    </MainLayoutStyle>
  )
})

export { element as MainLayout }
