import { styled } from '@creditas/stylitas'
import { Typography } from '@creditas/typography'
import { ButtonPrimaryTextOnly, RadioGroup, TextArea } from '@creditas-ui/react'
import { Col } from 'antd'
import { FiDownload } from 'react-icons/fi'

const ButtonDocs = styled.button`
  background-color: #fff;
  border: 0;
  cursor: pointer;
  color: #ff7711;
  font-size: 22px;
`

const ButtonRejectLead = styled(ButtonPrimaryTextOnly)`
  float: right;
  background-color: #fff !important;
  border: 2px solid #dc3838 !important;
  margin: 4% !important;
`

const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const DivShowDocIconText = styled.div`
  display: flex;
  justify-content: center;
`

const LabelShowDoc = styled.label`
  margin-left: 1%;
  cursor: pointer;
`

const Label = styled(Typography)`
  color: ${({ theme }) => theme.bColorNeutral60};
  font-size: 16px;
  font-weight: 400;
`

const OpenModalDocLabel = styled.div`
  color: ${({ theme }) => theme.bColorNeutral60};
  font-size: 16px;
  font-weight: 400;
  color: rgba(61, 156, 150, 1);
  cursor: pointer;
  #docLabel {
    justify-content: center > i {
      margin: auto;
    }
  }
`

const ContractViewerLabel = styled(Typography)`
  color: ${({ theme }) => theme.bColorNeutral60};
  font-size: 16px;
  font-weight: 400;
  color: rgba(17, 187, 119, 1);
  cursor: pointer;
`

const ButtonRequestNewIncomeDocs = styled(ButtonPrimaryTextOnly)`
  background-color: #b46100 !important;
  margin: 2% !important;
`

const ButtonTextConfirmedIncome = styled(Typography)`
  font-family: Helvetica Now Display;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.30000001192092896px;
  text-align: center;
`

const Title = styled(Typography)`
  color: ${({ theme }) => theme.bColorNeutral90};
  margin-bottom: 40px;
  font-weight: 300;
`

const Value = styled(Typography)`
  color: ${({ theme }) => theme.bColorNeutral90};
  font-size: 16px;
  font-weight: 600;
`

const RadioRow = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
`

const ColWithBorder = styled(Col)`
  border: 1px solid #eee;
  margin: 15px 0;
  padding: 10px;
`

const DownloadIcon = styled(FiDownload)`
  margin-right: 10px;
`

const RadioGroupStyled = styled(RadioGroup)`
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: space-evenly;
`

const CommentRow = styled.div`
  flex-direction: row;
  display: flex;
  padding-left: 9%;
  padding-top: 1%;
`

const InputTextAreaStyled = styled(TextArea)`
  margin-left: 2%;
`

const PersonBorder = styled(Col)`
  border: 1px solid #eee;
  margin: 15px 0;
  padding: 10px;
`

const PersonMargin = styled(Col)`
  margin: 15px;
`

const ButtonConfirmedIncome = styled(ButtonPrimaryTextOnly)`
  border-radius: 16px;
  margin: 2% !important;
`

const ButtonTextRequestNewIncomeDocs = styled(Typography)`
  font-family: Helvetica Now Display;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.30000001192092896px;
  text-align: center;
  color: #fff;
`

const ButtonTextRejectedLead = styled(Typography)`
  font-family: Helvetica Now Display;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.30000001192092896px;
  text-align: center;
  color: #dc3838;
`

const LabelTextRejectReason = styled(Typography)`
  font-family: Helvetica Now Display;
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: 0.5px;
  text-align: left;
  padding-bottom: 5%;
  margin: auto;
`

export {
  ButtonDocs,
  ButtonRejectLead,
  Item,
  Label,
  OpenModalDocLabel,
  ContractViewerLabel,
  ButtonRequestNewIncomeDocs,
  Title,
  Value,
  RadioRow,
  ColWithBorder,
  DownloadIcon,
  RadioGroupStyled,
  CommentRow,
  InputTextAreaStyled,
  PersonBorder,
  PersonMargin,
  DivShowDocIconText,
  LabelShowDoc,
  ButtonTextConfirmedIncome,
  ButtonConfirmedIncome,
  ButtonTextRequestNewIncomeDocs,
  ButtonTextRejectedLead,
  LabelTextRejectReason,
}
