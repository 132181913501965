import 'moment/locale/pt-br'
import moment from 'moment'
import { MainLayout } from '../../shared/components/MainLayout/MainLayout'
import {
  Content,
  Name,
  Title,
  DocDownloadClick,
  ModalBodyStyled,
  Box,
} from './LeadAnalysisHistory.styles'
import { Divider } from 'antd'
import { formatCpf } from '../../shared/components/helpers'
import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  ModalRoot,
  ModalHeader,
  ModalFooter,
} from '@creditas-ui/react'
import { Table, TableHead, TableRow, TableCell } from '../../shared/components/Table'
import { useEffect, useState } from 'react'
import { docNewTab } from '../../utils/docNewTab'
import { RequireAuth } from '../../utils/requiredAuthValidation'
import { IconDocumentsFileDownload, IconJustifyLeft1 } from '@creditas-ui/icons'
import { GlobalIcons } from '@creditas-ui/global-icons'

export default function LeadAnalysisHistory() {
  const [analysisHistoryData, setAnalysisHistoryData] = useState([{}])
  const [historyVersions, setHistoryVersions] = useState([{}])
  const [commentModal, setCommentModal] = useState({
    isOpen: false,
    comment: '',
  })
  const SHORT_COMMENT = 30

  const validateCommentCell = (comment) => {
    if (!comment) {
      return 'N/A'
    }

    if (comment.length <= SHORT_COMMENT) {
      return comment
    }

    return (
      <GlobalIcons
        Icon={IconJustifyLeft1}
        size='large'
        onClick={() =>
          setCommentModal({
            isOpen: true,
            comment: comment,
          })
        }
      />
    )
  }

  useEffect(() => {
    const pageData = JSON.parse(localStorage.getItem('pageData'))

    if (pageData) {
      setAnalysisHistoryData(pageData)
      setHistoryVersions(pageData.incomeProofs.filter((item) => item.readOnly).reverse())
    }

    localStorage.removeItem('pageData')
  }, [analysisHistoryData, historyVersions])

  return (
    <>
      <ModalRoot
        allowClose
        closeModal={() => setCommentModal({ ...commentModal, isOpen: false })}
        isOpen={commentModal.isOpen}
        size='medium'
      >
        <ModalHeader title='Comentário' />
        <ModalBodyStyled>{commentModal.comment}</ModalBodyStyled>
        <ModalFooter
          primaryLabel='Fechar'
          onPrimaryButtonClick={() => setCommentModal({ ...commentModal, isOpen: false })}
        />
      </ModalRoot>
      <RequireAuth navigationRoute={'/'}>
        <MainLayout hasPadding={false}>
          <Box>
            <Content>
              <Name variant='h3'>Histórico de análises</Name>
              <Title variant='h5'>
                {analysisHistoryData.fullName} - {formatCpf(analysisHistoryData.cpf)}
              </Title>
              <Divider />
              {historyVersions.map((historyVersionsItem, historyVersionsIndex) => (
                <Accordion key={historyVersionsIndex}>
                  <AccordionItem>
                    <AccordionHeader>
                      <h5 className={`accordion-title`}>V{historyVersionsItem.version}</h5>
                    </AccordionHeader>
                    <AccordionBody>
                      <Table>
                        <TableHead
                          headings={[
                            ' Data de Criação ',
                            ' Documento ',
                            ' Tipo ',
                            ' Status ',
                            ' Comentário ',
                          ]}
                        />
                        <tbody>
                          {historyVersionsItem?.docs?.map((docsItem, docsIndex) => (
                            <TableRow id={docsIndex} key={docsIndex}>
                              <TableCell>
                                {moment(docsItem.createdAt, 'DD-MM-YYYY HH:mm').format('LLL')}
                              </TableCell>
                              <TableCell>
                                <DocDownloadClick onClick={() => docNewTab(docsItem.docId)}>
                                  <GlobalIcons Icon={IconDocumentsFileDownload} size='large' />
                                </DocDownloadClick>
                              </TableCell>
                              <TableCell>{docsItem.typeDescription}</TableCell>
                              <TableCell>{docsItem.resultDescription}</TableCell>
                              <TableCell>{validateCommentCell(docsItem?.comment)}</TableCell>
                            </TableRow>
                          ))}
                        </tbody>
                      </Table>
                    </AccordionBody>
                  </AccordionItem>
                </Accordion>
              ))}
            </Content>
          </Box>
        </MainLayout>
      </RequireAuth>
    </>
  )
}
