import { styled } from '@creditas/stylitas'
import { Typography } from '@creditas/typography'

export const CardContainer = styled.div`
  height: 130px;
  border-top: 1px solid ${({ theme }) => theme.bColorNeutral10};
  border-bottom: 1px solid ${({ theme }) => theme.bColorNeutral10};
  padding: 32px 7vw;

  svg {
    position: absolute;
    right: 8vw;
  }
`

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.bColorNeutral90};
  font-weight: 400;
  font-size: 20px;
  text-decoration: none;
`

export const Subtitle = styled(Typography)`
  color: ${({ theme }) => theme.bColorNeutral60};
  font-weight: 400;
  font-size: 16px;
`

export const LinkStyle = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  border: 0;
  background-color: transparent;
  cursor: pointer;
`
