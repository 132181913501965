import { BookOpenedIcon, NotesIcon } from '../../shared/components/Icons'
import { ContractCheck } from '../../shared/components/Icons/ContractCheck'

const menuItems = {
  PROPOSALS: {
    name: 'Análise de crédito',
    id: 'propostas',
    icon: NotesIcon,
    link: '/dashboard',
  },
  SUPPORT_MATERIAL: {
    name: 'Detalhes dos leads',
    id: 'support-material',
    icon: BookOpenedIcon,
    link: `/leads`,
  },
  CONTRACTS: {
    name: 'Contratos',
    id: 'contracts',
    icon: ContractCheck,
    link: `/contracts`,
  },
}

const menuSize = {
  EXPANDED: '276',
  NOT_EXPANDED: '94',
}

export { menuSize, menuItems }
