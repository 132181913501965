import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
`

export const ContainerArea = styled.div`
  background-color: #fff;
  width: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const ContainerLogo = styled.div`
  display: flex;
  justify-content: center;
  background-color: #fff;
  width: 100%;
`

export const Logo = styled.img`
  width: 350px;
  height: 250px;
`

export const InputLogin = styled.input`
  width: 100%;
  outline: none;
  border: 1px solid #ddd;
  padding-left: 15px;
  height: 60px;
  font-size: 16px;
  border-radius: 5px;
`
