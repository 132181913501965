import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { LayoutWrapper } from '../../shared/components/Home.styles'
import { MainLayout } from '../../shared/components/MainLayout/MainLayout'

import { RequireAuth } from '../../utils/requiredAuthValidation'

import { Header } from '../../shared/components'
import { LeadDetailsView } from './components/LeadDetailsView'
import { LoadingDiv, TextDiv, Name } from './LeadDetails.styles'
import { Loading } from '../../shared/components/Icons'
import { toast } from 'react-toastify'
import { getLeadsListByOlxId } from '../../api/api'

const LeadDetails = () => {
  const { id } = useParams()

  const [leadDetailsData, setLeadDetailsData] = useState([])
  const [loading, setLoading] = useState(false)

  const loadLeadByIdOLX = async (OlxId) => {
    try {
      setLoading(true)
      const response = await getLeadsListByOlxId(OlxId)
      setLeadDetailsData(response.data)
    } catch (error) {
      toast.error('Ocorreu um erro inesperado! Atualize a página e tente novamente.')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadLeadByIdOLX(id)
  }, [])

  return (
    <RequireAuth navigationRoute={'/'}>
      <LayoutWrapper>
        <MainLayout>
          <Header title='GreyZone - Análise de Credito Detalhes' />
          {loading ? (
            <>
              <LoadingDiv>
                <Loading />
              </LoadingDiv>
              <TextDiv>
                <Name variant='h6'>A consulta pode demorar alguns segundos.</Name>
              </TextDiv>
            </>
          ) : (
            <LeadDetailsView leadDetailsData={leadDetailsData} />
          )}
        </MainLayout>
      </LayoutWrapper>
    </RequireAuth>
  )
}

export default LeadDetails
