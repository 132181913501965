import React from 'react'

const NotesIcon = () => {
  return (
    <svg viewBox='0 0 30 29' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14 21.9792H6C5.46957 21.9792 4.96086 21.7685 4.58579 21.3935C4.21071 21.0184 4 20.5097 4 19.9792V9.97925C4 9.44882 4.21071 8.94011 4.58579 8.56503C4.96086 8.18996 5.46957 7.97925 6 7.97925H14C14.5304 7.97925 15.0391 8.18996 15.4142 8.56503C15.7893 8.94011 16 9.44882 16 9.97925V19.9792C16 20.5097 15.7893 21.0184 15.4142 21.3935C15.0391 21.7685 14.5304 21.9792 14 21.9792Z'
        stroke='#1F2D27'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16 17.9792H18C18.5304 17.9792 19.0391 17.7685 19.4142 17.3935C19.7893 17.0184 20 16.5097 20 15.9792V5.97925C20 5.44882 19.7893 4.94011 19.4142 4.56503C19.0391 4.18996 18.5304 3.97925 18 3.97925H10C9.46957 3.97925 8.96086 4.18996 8.58579 4.56503C8.21071 4.94011 8 5.44882 8 5.97925V7.97925'
        stroke='#1F2D27'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M8 12.9792H12' stroke='#1F2D27' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8 16.9792H12' stroke='#1F2D27' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}

export { NotesIcon }
