import { styled } from '@creditas/stylitas'
import { Typography } from '@creditas/typography'

const Item = styled.div`
  display: flex;
  flex-direction: row;
`

const Label = styled(Typography)`
  color: ${({ theme }) => theme.bColorNeutral60};
  font-size: 16px;
  font-weight: 400;
`

const Value = styled(Typography)`
  color: ${({ theme }) => theme.bColorNeutral90};
  font-size: 16px;
  font-weight: 600;
  padding-left: 2%;
`

export { Item, Label, Value }
