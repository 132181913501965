import { ButtonPrimaryTextOnly, css, styled, selectTheme } from '@creditas-ui/react'
// import { ArrowRightIcon } from 'shared/ui/Icons'

const View = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 60px 0;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 0px;
  grid-template-areas: '. left left left left . . right right right . .';
`

const StyledCenterIcon = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 60px;
    height: 60px;
  }
`

const gridArea = ({ area }) =>
  area &&
  css`
    grid-area: ${area};
  `
const GridItem = styled.div`
  ${gridArea}
`

const DocumentsUploadWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 288px;
`

const Button = styled(ButtonPrimaryTextOnly)`
  margin-top: 32px;
`

const DocumentPageTitle = styled.h2`
  font-family: Helvetica Now Display;
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 52px;
  letter-spacing: 0.5px;
  color: ${selectTheme('colors.neutral.90')};
`

const DocumentPageSubtitle = styled.p`
  font-family: Helvetica Now Display;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: ${selectTheme('colors.neutral.90')};
  margin-top: 20px;
  max-width: 392px;
`

// const DocumentArrowLeftIcon = styled(ArrowRightIcon)`
//   transform: rotate(180deg);
//   width: 32px;
//   height: 32px;
// `

export {
  View,
  StyledCenterIcon,
  Grid,
  GridItem,
  DocumentsUploadWrapper,
  Button,
  DocumentPageTitle,
  DocumentPageSubtitle,
}
