import React from 'react'
import { styled } from '@creditas/stylitas'
import { compose, withDefaultProps, withForwardRef } from '@creditas/utils'

const TableHeadStyle = styled.th`
  color: ${({ theme }) => theme.bColorNeutral90};
  text-align: left;
  padding: 24px;
  font-size: 14px;
  font-weight: 700;
`

const propTypes = {}

const defaultProps = {
  component: 'thead',
}

const enhancer = compose(withForwardRef, withDefaultProps(propTypes, defaultProps))

const element = enhancer(function TableHead(props) {
  const { headings, withRef, className, ...other } = props

  return (
    <thead ref={withRef} className={className} {...other}>
      <tr>
        {headings.map((heading, index) => (
          <TableHeadStyle key={index}>{heading}</TableHeadStyle>
        ))}
      </tr>
    </thead>
  )
})

export { element as TableHead }
