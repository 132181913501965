import React from 'react'
import { string } from 'prop-types'
import { compose, withDefaultProps, withForwardRef } from '@creditas/utils'
import { ArrowRightIcon } from '../Icons'
import { CardContainer, Title, Subtitle, LinkStyle } from './components/Card.styles'
import { useNavigate } from 'react-router-dom'

const propTypes = {
  title: string,
  subtitle: string,
  linkTo: string,
}

const defaultProps = {
  component: 'div',
}

const enhancer = compose(withForwardRef, withDefaultProps(propTypes, defaultProps))

const element = enhancer(function Card(props) {
  const { title, subtitle, linkTo, testId, ref, component, className, ...other } = props
  const navigate = useNavigate()

  return (
    <CardContainer data-testid={testId} ref={ref} as={component} className={className} {...other}>
      <LinkStyle onClick={() => navigate(linkTo)} data-testid={`link-${testId}`}>
        <Title>{title}</Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        <ArrowRightIcon aria-hidden={true} />
      </LinkStyle>
    </CardContainer>
  )
})

export { element as Card }
