import { styled } from '@creditas/stylitas'
import { Collapse, Divider } from 'antd'
import { Typography } from '@creditas/typography'

const StyledCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10vh;
`

const TextDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const LoadingDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10vh;
  padding-bottom: 2vh;
  svg {
    width: 60px;
    height: 60px;
  }
`

const StyledCollapse = styled(Collapse)`
  width: 850px;
  background-color: #fff;
  font-size: 18px;
`

const StyledDivider = styled(Divider)`
  background-color: #000;
  width: 2px;
`

const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;
  margin-left: 20%;
  margin-right: 20%;
`

const Label = styled(Typography)`
  color: ${({ theme }) => theme.bColorNeutral60};
  font-size: 16px;
  font-weight: 400;
`

const Value = styled(Typography)`
  color: ${({ theme }) => theme.bColorNeutral90};
  font-size: 16px;
  font-weight: 600;
`

const Name = styled(Typography)`
  color: ${({ theme }) => theme.bColorNeutral90};
  font-weight: 300;

  span {
    font-weight: 700;
  }
`

const BoldValue = styled.span`
  color: ${({ theme }) => theme.bColorNeutral90};
  font-weight: 700;
`

const SearchBarView = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 20%;
  margin-right: 20%;
  padding-bottom: 30px;
`

const StyledBox = styled(Typography)`
  padding-top: 10px;
  margin-left: 20%;
  margin-right: 20%;
`

export {
  StyledCenter,
  TextDiv,
  LoadingDiv,
  StyledCollapse,
  StyledDivider,
  Item,
  Label,
  Value,
  Name,
  BoldValue,
  SearchBarView,
  StyledBox,
}
