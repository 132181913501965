import { css } from '@creditas/stylitas'

const modal = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 80;
`

const modalOverlay = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 90;
`

const modalBody = css`
  position: fixed;
  height: 100vh;
  z-index: 100;
  background: white;
  margin-left: auto;
  right: 0;
  width: 41%;

  @media (max-width: 768px) {
    width: 100%;
  }
`

const modalContent = css`
  position: relative;
  height: 100%;
  z-index: 100;
  background: white;
  margin-left: auto;
  padding: 13vh 0 0 0;
`

const modalButtonClose = css`
  border: none;
  background: none;
  position: absolute;
  top: 60px;
  right: 60px;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export { modal, modalOverlay, modalBody, modalContent, modalButtonClose }
