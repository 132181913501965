import { styled } from '@creditas/stylitas'
import { Document, pdfjs } from 'react-pdf'
import { GlobalIcons, TextArea } from '@creditas-ui/react'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
import { Typography } from '@creditas/typography'

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker

const CloseIconStyled = styled(GlobalIcons)`
  display: flex;
  justify-content: right;
  align-items: right;
  cursor: pointer;
  margin-right: 3%;
`

const ModalDocViewerStyled = styled.div`
  position: fixed; /* Stay in place */
  z-index: 99; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
`

const TextDivLeft = styled.div`
  height: 24px;
  font-family: 'Helvetica Now Display';
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #1f2d27;
  order: 0;
  margin-left: 3%;
`

const CreditasDocViewerStyled = styled(Document)`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`

const CreditasHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
  gap: 20px;
  height: 10%;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 0px;
  align-items: center;
`

const CreditasFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 10%;
  background: #ffffff;
  box-shadow: 0px 28px 64px rgba(31, 45, 39, 0.16);
  border-radius: 0px 0px 16px 16px;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
`

const PageWrapper = styled.div``

const PdfContainer = styled.div`
  overflow-y: scroll;
  max-height: 80% !important;
  border: none;
  display: flex;
  width: 100%;
`

const ImageContainer = styled.div`
  max-height: 80% !important;
  height: 80% !important;
  border: none;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

const Image = styled.img`
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
`

const ButtonView = styled.div`
  display: flex;
  flex-direction: row;
  width: 20%;
`

const ButtonPadding = styled.div`
  padding: 0 5%;
  display: flex;
`

const StyledTextArea = styled(TextArea)`
  width: 100%;
`

const ModalBodyView = styled(Typography)`
  padding: 5px 25px;
`

const ModalTitle = styled(Typography)`
  color: ${({ theme }) => theme.bColorNeutral90};
  font-size: 22px;
  font-weight: 500;
`

const ModalSubtitle = styled(Typography)`
  color: ${({ theme }) => theme.bColorNeutral90};
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 300;
`

export {
  CreditasDocViewerStyled,
  TextDivLeft,
  CreditasHeader,
  CloseIconStyled,
  CreditasFooter,
  ModalDocViewerStyled,
  PageWrapper,
  PdfContainer,
  ImageContainer,
  Image,
  ButtonView,
  ButtonPadding,
  StyledTextArea,
  ModalBodyView,
  ModalTitle,
  ModalSubtitle,
}
