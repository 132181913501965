import React, { useState, useRef, useEffect, useCallback } from 'react'
import { compose, withDefaultProps, withForwardRef } from '@creditas/utils'
import { CreditasLogoIcon, CreditasLogoHorizontal } from '../Icons'
import { menuItems, menuSize } from '../../../domain/enums'
import { MenuItem } from './MenuItem/MenuItem'
import { Profile } from './Profile/Profile'
import { Container, IconContainer, Navigation, Line } from './components/Sidebar.styles'

const propTypes = {}

const defaultProps = {
  component: 'aside',
  menuItem: menuItems.PROPOSALS.id,
}

const enhancer = compose(withForwardRef, withDefaultProps(propTypes, defaultProps))

const element = enhancer(function Sidebar(props) {
  const { component, className, menuItem, ...other } = props
  const [expanded, setExpanded] = useState(false)
  const [localMenuItems] = useState(menuItems)
  const [activeMenuItem, setActiveMenuItem] = useState(menuItem)
  const sidebar = useRef()
  const marker = useRef()

  const expandSidebar = () => {
    sidebar.current.style.width = `${menuSize.EXPANDED}px`
    setTimeout(() => {
      const width = sidebar.current.offsetWidth
      // eslint-disable-next-line eqeqeq
      if (width == menuSize.EXPANDED) {
        window.document.getElementById('main-layout').style.marginLeft = `${menuSize.EXPANDED}px`
        setExpanded(true)
      }
    }, 150)
  }

  const closeSidebar = () => {
    sidebar.current.style.width = `${menuSize.NOT_EXPANDED}px`
    setTimeout(() => {
      const width = sidebar.current.offsetWidth
      // eslint-disable-next-line eqeqeq
      if (width == menuSize.NOT_EXPANDED) {
        window.document.getElementById(
          'main-layout',
        ).style.marginLeft = `${menuSize.NOT_EXPANDED}px`
        setExpanded(false)
      }
    }, 150)
  }

  const changeActiveMenuItem = (e) => {
    if (e !== null) {
      marker.current.style.display = 'block'
      marker.current.style.top = `${e.closest('li').offsetTop}px`
      marker.current.style.height = `${e.closest('li').offsetHeight}px`
      setActiveMenuItem(e.closest('li').id)
    }
  }

  const updateActiveMenuItem = useCallback(() => {
    const currentMenuItem = window.document.getElementById(activeMenuItem)
    changeActiveMenuItem(currentMenuItem)
  }, [activeMenuItem])

  useEffect(() => {
    updateActiveMenuItem()
  }, [updateActiveMenuItem])

  return (
    <Container
      data-testid='sidebar'
      ref={sidebar}
      as={component}
      className={className}
      onFocus={() => expandSidebar()}
      onBlur={() => closeSidebar()}
      onMouseEnter={() => expandSidebar()}
      onMouseLeave={() => closeSidebar()}
      expanded={expanded}
      {...other}
    >
      <IconContainer>{expanded ? <CreditasLogoHorizontal /> : <CreditasLogoIcon />}</IconContainer>

      <Navigation>
        <ul>
          {Object.values(localMenuItems).map((item, key) => (
            <MenuItem
              key={key}
              id={item.id}
              link={item.link}
              expanded={expanded}
              Icon={item.icon}
              label={item.name}
              active={activeMenuItem === item.id}
              changeActiveMenuItem={changeActiveMenuItem}
            />
          ))}
        </ul>
        <Line ref={marker} />
      </Navigation>

      <Profile expanded={expanded} />
    </Container>
  )
})

export { element as Sidebar }
