import React from 'react'
import { styled } from '@creditas/stylitas'
import { compose, withDefaultProps, withForwardRef } from '@creditas/utils'

const TableRowStyle = styled.tr`
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.bColorNeutral10};
  &:hover {
    background-color: ${({ theme }) => theme.bColorNeutral10};
  }
`
const propTypes = {}

const defaultProps = {
  component: 'tr',
}

const enhancer = compose(withForwardRef, withDefaultProps(propTypes, defaultProps))

const element = enhancer(function TableRow(props) {
  const { ref, className, children, ...other } = props

  return (
    <TableRowStyle ref={ref} className={className} {...other}>
      {children}
    </TableRowStyle>
  )
})

export { element as TableRow }
