import { Box, ButtonPrimaryTextOnly } from '@creditas-ui/react'
import { Divider } from 'antd'
import {
  StyledCenter,
  StyledDivider,
  Item,
  Label,
  Name,
  Value,
  StyledBox,
  BoldValue,
} from '../LeadsList.styles'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

export const LeadsDetailsList = ({ leadsDetailData, hasSearch }) => {
  let navigate = useNavigate()

  const navigateToLeadByOlxId = (olxId) => {
    navigate('/lead/' + olxId + '/details')
  }

  if (leadsDetailData.length === 0 && hasSearch) {
    return (
      <StyledCenter>
        <Name variant='h5'>A sua pesquisa não encontrou registros correspondentes.</Name>
      </StyledCenter>
    )
  }

  return (
    <>
      {leadsDetailData?.map((leadDetailsItem) => (
        <>
          <div key={leadDetailsItem?.olxId}>
            <Box>
              <br />
              <Item>
                <Name variant='h5'> Lead: {leadDetailsItem?.olxId}</Name>
              </Item>
              <br />
              <Item>
                <Name variant='h6'>
                  {' '}
                  Status:
                  <BoldValue>{' ' + leadDetailsItem?.statusDescription}</BoldValue>
                </Name>
              </Item>
              <Item>
                <Name variant='h6'>
                  Data de criação:{' '}
                  {moment(leadDetailsItem?.createDate, 'DD/MM/YYYY HH:mm').format('L LT')}
                </Name>
              </Item>
            </Box>
            {leadDetailsItem?.profileAnalysis?.map((profileItem) => (
              <>
                {profileItem?.persons?.map((personItem) => (
                  <>
                    <Box>
                      <Item>
                        <Label>Nome proponente:</Label>
                        <Value>{personItem?.name}</Value>
                      </Item>
                      <Item>
                        <Label>CPF</Label>
                        <Value>{personItem?.cpf}</Value>
                      </Item>
                      <Item>
                        <Divider dashed />
                      </Item>
                    </Box>
                  </>
                ))}
              </>
            ))}
          </div>

          <StyledBox>
            <ButtonPrimaryTextOnly
              data-testid='btn-new-proposal'
              variant='primary'
              onClick={() => navigateToLeadByOlxId(leadDetailsItem?.olxId)}
            >
              Detalhes do Lead
            </ButtonPrimaryTextOnly>
          </StyledBox>

          <Item>
            <StyledDivider />
          </Item>
        </>
      ))}
    </>
  )
}
