import React from 'react'
import { standard, ThemeContext } from '@creditas-ui/react'
import { node } from 'prop-types'

const ApplyTheme = ({ children }) => {
  return <ThemeContext.Provider value={{ ...standard }}>{children}</ThemeContext.Provider>
}

ApplyTheme.propTypes = {
  children: node,
}

export { ApplyTheme }
