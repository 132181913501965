import React from 'react'
import 'antd/dist/antd.css'

import { ThemeProvider } from '@creditas/stylitas'
import { Global } from '@creditas/global'
import tokens from '@creditas/tokens'

import { colorPalette } from './shared/components/helpers/colorPalette'
import { ApplyTheme } from './shared/components/ApplyTheme'
import { ModalProvider } from './shared/components/Modal/ModalContext'

import { ToastContainer } from 'react-toastify'

import Routes from './Routes/routes'
require('dotenv').config()

function App() {
  return (
    <ThemeProvider theme={{ ...tokens, ...colorPalette }}>
      <ApplyTheme>
        <Global />
        <ModalProvider>
          <ToastContainer autoClose={3000} />
          <Routes />
        </ModalProvider>
      </ApplyTheme>
    </ThemeProvider>
  )
}

export default App
