import { Box } from '@creditas-ui/react'
import { ContractStatusValue, Item, Label, Name, Value } from '../LeadContractDetails.styles'
import {
  ButtonDocs,
  ContractViewerLabel,
} from '../../../Lead/components/PersonItens/PersonItens.styles'
import { useState } from 'react'
import { formatCpf } from '../../../../shared/components/helpers'
import { ModalDocViewer } from '../../../../shared/components/ModalDocViewer/ModalDocViewer'
import { Divider } from 'antd'

const contractFormatMap = {
  DIGITAL: 'Digital',
  SCANNED: 'Escaneado',
  INVALID: 'Inválido',
}

export const LeadContractDetailsView = ({ contractDetailsData }) => {
  const [viewContract, setViewContract] = useState(false)
  const visibility = (bool) => setViewContract(bool)

  function callDocData() {
    setViewContract(true)
  }

  return (
    <>
      {viewContract && (
        <ModalDocViewer
          idDoc={contractDetailsData?.idDocContract}
          viewContractBool={visibility}
          title={contractDetailsData?.leadName}
          hasValidationButtons
          idOlx={contractDetailsData?.idOlx}
          closeModalDocViewer={setViewContract}
        />
      )}
      {!viewContract && (
        <div>
          <Box>
            <br />
            <Item>
              <Name variant='h5'> Lead: {contractDetailsData?.idOlx}</Name>
            </Item>
          </Box>

          <Box>
            <Item>
              <Label>Status da análise</Label>
              <ContractStatusValue>{contractDetailsData?.contractStatusDesc}</ContractStatusValue>
            </Item>
            <Item>
              <Label>Imobiliária</Label>
              <Value>{contractDetailsData?.realEstateName}</Value>
            </Item>
            <Item>
              <Label>CNPJ Imobiliária</Label>
              <Value>
                {contractDetailsData?.realEstateCnpj?.replace(
                  /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                  '$1.$2.$3/$4-$5',
                )}
              </Value>
            </Item>
            <Item>
              <Label>Valor do pacote</Label>
              <Value>R$ {parseFloat(contractDetailsData?.packageRent).toFixed(2)}</Value>
            </Item>
            <Item>
              <Label>Nome</Label>
              <Value>{contractDetailsData?.leadName}</Value>
            </Item>
            <Item>
              <Label>CPF</Label>
              <Value>{formatCpf(contractDetailsData?.leadDocument)}</Value>
            </Item>
            <Item>
              <Label>Formato do Documento</Label>
              <Value>{contractFormatMap[contractDetailsData?.contractFormatType]}</Value>
            </Item>
            <Item>
              <Label>Match (%)</Label>
              <Value>{contractDetailsData?.matchPercentage ?? 'N/A'}</Value>
            </Item>
            <Item>
              <ButtonDocs>
                <ContractViewerLabel
                  onClick={() => callDocData(contractDetailsData?.idDocContract)}
                >
                  Visualizar contrato
                </ContractViewerLabel>
              </ButtonDocs>
            </Item>
            <Item>
              <Divider />
            </Item>
          </Box>
        </div>
      )}
    </>
  )
}
