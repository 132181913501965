import React, { useState, useRef, useEffect } from 'react'
import { compose, withDefaultProps, withForwardRef } from '@creditas/utils'
import { ArrowDownIcon, ArrowUpIcon } from '../../Icons'
import {
  FooterContainer,
  ProfileOptions,
  ProfileContainer,
  ProfileButton,
  NameContainer,
  SourceTitle,
  SourceSubtitle,
  CustomAvatar,
  Link,
  Role,
} from './components/Profile.styles'
import jwt_decode from 'jwt-decode'
import { logout } from '../../../../api/api'

const propTypes = {}

const defaultProps = {
  component: 'footer',
}

const enhancer = compose(withForwardRef, withDefaultProps(propTypes, defaultProps))

const element = enhancer(function Profile(props) {
  const tokenLocal = localStorage.getItem('home-solutions-frontend')
  const tokenUser = jwt_decode(tokenLocal)

  const { expanded, withRef, component, className, ...other } = props
  const [expandedOptions, setExpandedOptions] = useState(expanded)

  const profileOptions = useRef()

  const toggleProfileOptions = () => {
    profileOptions.current.style.height = profileOptions.current.offsetHeight === 0 ? '100%' : '0px'
    setExpandedOptions(!expandedOptions)
  }

  const resetExpendedOptions = () => {
    setExpandedOptions(false)
  }

  useEffect(() => {
    if (profileOptions.current) {
      resetExpendedOptions()
    }
  }, [expanded])

  return (
    <FooterContainer ref={withRef} as={component} className={className} {...other}>
      <div>
        <CustomAvatar fullname={tokenUser.username ? tokenUser.username : 'HS'} />
      </div>
      {expanded && (
        <ProfileContainer>
          <NameContainer>
            <p></p>
            <ProfileButton
              aria-label='Opções do perfil'
              onFocus={() => toggleProfileOptions()}
              onClick={() => toggleProfileOptions()}
            >
              {expandedOptions ? <ArrowDownIcon /> : <ArrowUpIcon />}
            </ProfileButton>
          </NameContainer>

          <Role></Role>

          <ProfileOptions ref={profileOptions} expandedOptions={expandedOptions}>
            <li>
              <SourceTitle>{tokenUser.username}</SourceTitle>
              <SourceSubtitle></SourceSubtitle>
            </li>
            <li>
              <Link aria-label='Sair' onClick={() => logout()}>
                Sair
              </Link>
            </li>
          </ProfileOptions>
        </ProfileContainer>
      )}
    </FooterContainer>
  )
})

export { element as Profile }
