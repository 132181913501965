import React from 'react'
import { compose, withDefaultProps, withForwardRef } from '@creditas/utils'
import { bool } from 'prop-types'
import { ListItem, Link, MenuIconContainer } from './components/MenuItem.styles'
import { useNavigate } from 'react-router-dom'

const propTypes = {
  active: bool,
}

const defaultProps = {
  component: 'li',
}

const enhancer = compose(withForwardRef, withDefaultProps(propTypes, defaultProps))

const element = enhancer(function MenuItem(props) {
  const {
    withRef,
    component,
    className,
    id,
    expanded,
    link,
    Icon,
    label,
    active,
    changeActiveMenuItem,
    ...other
  } = props
  const navigate = useNavigate()

  const setWindowLocation = (linkTo) => {
    navigate(linkTo)
  }

  return (
    <ListItem id={id} ref={withRef} as={component} className={className} {...other}>
      <Link
        aria-label={label}
        active={active}
        onClick={(e) => {
          changeActiveMenuItem(e.target)
          setWindowLocation(link)
        }}
      >
        <MenuIconContainer aria-hidden={true}>
          <Icon />
        </MenuIconContainer>{' '}
        {expanded && label}
      </Link>
    </ListItem>
  )
})

export { element as MenuItem }
