const tokenLocal = localStorage.getItem('home-solutions-frontend')

let myHeadersTab = new Headers()
myHeadersTab.append('Content-Type', 'application/json')
myHeadersTab.append('Authorization', `Bearer ${tokenLocal}`)

const requestOptionsTab = {
  method: 'GET',
  headers: myHeadersTab,
  redirect: 'follow',
}

export { requestOptionsTab }
