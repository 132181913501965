import { useEffect, useState } from 'react'
import { Header } from '../../../shared/components'
import { LayoutWrapper } from '../../../shared/components/Home.styles'
import { MainLayout } from '../../../shared/components/MainLayout/MainLayout'
import { Error } from '../../../shared/components/Error/Error'
import { StyledCenterIcon } from '../../../shared/components/Document/Document.style'
import { Loading } from '../../../shared/components/Icons'
import { getContractDetails, logout } from '../../../api/api'
import { RequireAuth } from '../../../utils/requiredAuthValidation'
import { useParams } from 'react-router-dom'
import { LeadContractDetailsView } from './components/LeadContractDetailsView'

const LeadContractDetails = () => {
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [leadContractDetails, setLeadContractDetails] = useState([])

  const loadLeadContractDetails = async (olxId) => {
    try {
      setLoading(true)
      const response = await getContractDetails(olxId)
      setLeadContractDetails(response.data)
    } catch (error) {
      setError(true)
      if (error?.response?.status === 401) {
        logout()
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadLeadContractDetails(id)
  }, [])

  if (loading) {
    return (
      <StyledCenterIcon>
        <Loading />
      </StyledCenterIcon>
    )
  }

  return (
    <RequireAuth navigationRoute={'/'}>
      <LayoutWrapper>
        <MainLayout>
          <Header title={leadContractDetails.leadName} />
          {!loading && <LeadContractDetailsView contractDetailsData={leadContractDetails} />}
          {!loading && error && <Error />}
        </MainLayout>
      </LayoutWrapper>
    </RequireAuth>
  )
}

export default LeadContractDetails
