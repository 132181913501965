const tokenLocal = localStorage.getItem('home-solutions-frontend') || null

const configLeads = {
  headers: {
    Accept: 'application/vnd.creditas.v1+json',
    Authorization: `Bearer ${tokenLocal}`,
  },
}

const configLeadsDetail = {
  headers: {
    Authorization: `Bearer ${tokenLocal}`,
  },
}

const configLeadHistory = {
  headers: {
    Authorization: `Bearer ${tokenLocal}`,
  },
}

const configLeadByLeadId = {
  headers: {
    Authorization: `Bearer ${tokenLocal}`,
    Accept: 'application/vnd.creditas.v1+json',
  },
}

const configManualAnalysis = {
  headers: {
    Authorization: `Bearer ${tokenLocal}`,
    'Content-Type': 'application/json',
  },
}

const configLeadsListByDocument = {
  headers: {
    Authorization: `Bearer ${tokenLocal}`,
    Accept: 'application/vnd.creditas.v1+json',
  },
}

const configGetInfoDoc = {
  headers: {
    Authorization: `Bearer ${tokenLocal}`,
    Accept: 'application/vnd.creditas.v1+json',
    'Content-Type': 'application/json',
  },
  data: {},
}

const configGetInfoDocBlob = {
  headers: {
    Authorization: `Bearer ${tokenLocal}`,
    Accept: 'application/vnd.creditas.v1+json',
    'Content-Type': 'application/octet-stream',
  },
  responseType: 'blob',
  data: {},
}

const configPendingContracts = {
  headers: {
    Authorization: `Bearer ${tokenLocal}`,
    Accept: 'application/vnd.creditas.v1+json',
  },
}

const configContractDetails = {
  headers: {
    Authorization: `Bearer ${tokenLocal}`,
    Accept: 'application/vnd.creditas.v1+json',
  },
}

const configContractAnalysis = {
  headers: {
    Authorization: `Bearer ${tokenLocal}`,
    'Content-Type': 'application/json',
  },
}

export {
  configLeads,
  configLeadsDetail,
  configLeadHistory,
  configLeadByLeadId,
  configManualAnalysis,
  configLeadsListByDocument,
  configGetInfoDoc,
  configGetInfoDocBlob,
  configPendingContracts,
  configContractDetails,
  configContractAnalysis,
}
