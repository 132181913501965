import { styled } from '@creditas/stylitas'

const Container = styled.aside`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
  width: 94px;
  position: fixed;
  top: 0;
  left: 0;
  padding: 56px 22px;
  background-color: ${({ theme }) => theme.bColorNeutralWhite};
  transition: width 0.1s ease-in-out;
  border-right: 1px solid ${({ theme }) => theme.bColorNeutral10};
  overflow: hidden;
  white-space: nowrap;
`

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
`

const Navigation = styled.nav`
  position: relative;
`

const Line = styled.div`
  display: none;
  position: absolute;
  width: 4px;
  height: 60px;
  background-color: ${({ theme }) => theme.bColorPrimaryDefault};
  left: -22px;
  top: 0;
  border-radius: 0 4px 4px 0;
  transition: 0.5s;
`
export { Container, IconContainer, Navigation, Line }
