import React from 'react'

const CloseIcon = (props) => (
  <svg
    {...props}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M15.2003 0.799951L0.800293 15.2' stroke='#141414' strokeLinecap='round' />
    <path d='M0.800195 0.799953L15.2002 15.2' stroke='#141414' strokeLinecap='round' />
  </svg>
)

export { CloseIcon }
