import { DocumentsApi } from '../services/api'
import { requestOptionsTab } from '../adapter/docs/config'
import { toast } from 'react-toastify'

export const docNewTab = (docId) => {
  fetch(`${DocumentsApi}/${docId}?getUrl=true`, requestOptionsTab)
    .then((response) => response.text())
    .then((result) => {
      const res = JSON.parse(result)
      window.open(res.url, '_blank', 'noopener,noreferrer')
      if (result) {
        toast.success('Documento carregado com sucesso')
        return result
      }
    })
    .catch((error) => {
      toast.error('Erro ao gerar carregar, tente novamente mais tarde')
      console.log('error', error)
    })
}
